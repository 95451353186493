import React, { useEffect, useState } from 'react';
import { Drawer, Footer, ScreenContainer } from '@shared/components';
import app from '@services/firebase';

import {
  CountryCard,
  GlobeWrapper,
  Wrapper,
  CountriesWrapper
} from './styles';

interface WorldData {
  id: string;
  flag: string;
  region: string;
  country: string;
  subregion: string;
  alpha2Code: string;
  alpha3Code: string;
}

const options = [
  {
    label: 'América do Sul',
    checker: 'South America',
    value: 'SA'
  },
  {
    label: 'América Central',
    checker: 'Central America',
    value: 'CA'
  },
  {
    label: 'América do Norte',
    checker: 'North America',
    value: 'NA'
  },
  {
    label: 'África',
    checker: 'Africa',
    value: 'AF'
  },
  {
    label: 'Europa',
    checker: 'Europa',
    value: 'EU'
  },
  { label: 'Ásia', checker: 'Asia', value: 'AS' },
  {
    label: 'Oceania',
    checker: 'Oceania',
    value: 'OC'
  }
];

const OnlineUsers = () => {
  const [filter, setFilter] = useState<string>('');
  const [data, setData] = useState<WorldData[]>([]);

  useEffect(() => {
    const dbRef = app.database().ref('/users/online');

    const subscribe = dbRef.child('/').on('value', (snapshot) => {
      const onlineCountry: WorldData[] = [];
      snapshot.forEach((snap) => {
        const data = snap.val();

        onlineCountry.push(data);
        return undefined;
      });

      setData(onlineCountry);
    });

    return () => dbRef.off('value', subscribe);
  }, []);

  return (
    <Drawer>
      <ScreenContainer>
        <Wrapper>
          <h3>Usuários Online</h3>
          <GlobeWrapper>
            <iframe
              title="world"
              src="https://books-275121.firebaseapp.com/"
            />
          </GlobeWrapper>
          <select
            onChange={({ target: { value } }) => setFilter(value)}
            value={filter}
            placeholder="Selecione a região"
          >
            <option value="">selecione</option>
            {options.map(({ label, value }) => (
              <option value={value}>{label}</option>
            ))}
          </select>

          <CountriesWrapper>
            <div className="tableRows">
              <label>País</label>
              <label>Usuários Online</label>
            </div>
            {data
              .filter(
                (elem, index, self) =>
                  index ===
                  self.findIndex((t) => t.country === elem.country)
              )
              .filter((fill) =>
                filter
                  ? fill.region === filter
                  : fill.region !== filter
              )
              .map((item, index) => {
                return (
                  <CountryCard key={`flag-${index + 0}`}>
                    <div>
                      <img
                        alt="flag"
                        src={
                          item.alpha2Code
                            ? `https://www.countryflags.io/${item.alpha2Code}/flat/64.png`
                            : 'https://4.bp.blogspot.com/-jaC46VvY4eg/XS_bPb-Z2TI/AAAAAAAAtyc/r5Lt66cTWmYDN1ZRjIoR8LYyCo9wyxjnwCLcBGAs/s1600/bandeira-internacional-da-terra.png'
                        }
                      />
                      <label>{item.country || 'Internacional'}</label>
                    </div>
                    <label>
                      {
                        data.filter(
                          (filter) => filter.country === item.country
                        ).length
                      }
                    </label>
                  </CountryCard>
                );
              })}
          </CountriesWrapper>
        </Wrapper>
        <Footer />
      </ScreenContainer>
    </Drawer>
  );
};

export default OnlineUsers;
