import moment from 'moment';

export const millisToSeconds = (millis: number | undefined) => {
  return moment.duration(millis).asSeconds();
};

export const millisToMinutes = (millis: number | undefined) => {
  return moment.utc(millis).format('H:mm:ss');
};

export const secondsToMinutes = (seconds: number | undefined) => {
  return moment.utc(seconds, 'SSS').format('H:mm:ss');
};
