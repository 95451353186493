import { useRef, useCallback, useEffect } from 'react';

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(
    useCallback(() => {
      savedCallback.current = callback;
    }, [callback])
  );

  // Set up the interval.
  useEffect(
    useCallback(() => {
      // Don't schedule if no delay is specified.
      if (delay === null) {
        return;
      }

      const id = setInterval(() => savedCallback.current(), delay);

      return () => clearInterval(id);
    }, [delay])
  );
}

export default useInterval;
