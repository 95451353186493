import { ReactNode } from 'react';
import { Gradient, Background } from './styles';

interface backgroundProps {
  children: ReactNode;
}

const BackgroundImage = ({ children }: backgroundProps) => {
  return (
    <Gradient>
      <Background>{children}</Background>
    </Gradient>
  );
};

export default BackgroundImage;
