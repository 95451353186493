import Colors from '@constants/Colors';
import React from 'react';
import styled from 'styled-components';

function CategoryIcon({
  image,
  title,
  selected,
  onClick
}: {
  image: string;
  title: string;
  selected: boolean;
  onClick: () => void;
}) {
  return (
    <Container
      background={image}
      selected={selected}
      onClick={onClick}
    >
      <div />
      <p>{title}</p>
    </Container>
  );
}

export default CategoryIcon;

const Container = styled.div<{
  background: string;
  selected: boolean;
}>`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease;

  :hover {
    transform: scale(1.05);
  }

  div {
    width: 150px;
    height: 170px;
    margin-bottom: 20px;
    border-radius: 15px;
    background-image: url(${(p) => p.background});
    background-size: cover;
    background-position: center;
    border-top: ${(p) =>
      p.selected ? `5px solid ${Colors.palette.primary}` : 'none'};
    border-bottom: ${(p) =>
      p.selected ? `5px solid ${Colors.palette.primary}` : 'none'};
  }

  p {
    font: 500 15px Rubik;
    color: #666;
  }
`;
