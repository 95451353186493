import React, { ReactNode } from 'react';

import { AuthProvider } from './AuthContext';
import { DrawerProvider } from '../shared/components/Drawer';
import { PlayerProvider } from './MusicPlayerContent';

function Providers({ children }: { children: ReactNode }) {
  return (
    <AuthProvider>
      <PlayerProvider>
        <DrawerProvider>{children}</DrawerProvider>
      </PlayerProvider>
    </AuthProvider>
  );
}

export default Providers;
