import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Button from '@shared/components/Button';

import AudioIcon from '@assets/images/icons/Audio.svg';
import VideoIcon from '@assets/images/icons/Video.svg';
import MaterialIcon from '@assets/images/icons/Material.svg';
import {
  ButtonBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover
} from '@mui/material';
import {
  DescriptionOutlined,
  MicOutlined,
  VideocamOutlined
} from '@mui/icons-material';
import { Document } from '@assets/images';
import api from '@services/api';
import isAxiosError from '@helpers/isAxiosError';
import { toast } from 'react-toastify';

interface CourseListItemProps {
  id: string;
  thumb: string;
  name: string;
  formats: string[];
  acquired?: boolean;
  onClick: () => void;
}

function CourseListItem({
  id,
  thumb,
  onClick,
  name,
  formats,
  acquired
}: CourseListItemProps) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] =
    useState<HTMLButtonElement | null>();
  const popoverOpen = Boolean(anchorEl);

  const handleRedirectToContent = (id: string, format: string) => {
    if (format === 'MATERIAL')
      return history.push({
        pathname: '/material',
        search: `?id=${id}`
      });

    history.push({
      pathname: '/media/course',
      search: `?id=${id}&format=${format}`
    });
  };

  const handleCert = async () => {
    try {
      const certificateUrl: string = await api.get(
        `/course/certificate/${id}`
      );
      window.open(certificateUrl);
    } catch (error) {
      if (isAxiosError(error)) {
        toast.info(
          'essa opção podera ser acessada ao final do curso!'
        );
      }
    }
  };

  const formatOptions = [
    <Popover
      open={popoverOpen}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disableRestoreFocus
    >
      <List>
        {formats?.map((item) => (
          <ListItem
            button
            onClick={() => handleRedirectToContent(id, item)}
          >
            <ListItemIcon>
              {item === 'AUDIO' && <MicOutlined />}
              {item === 'VIDEO' && <VideocamOutlined />}
              {item === 'MATERIAL' && <DescriptionOutlined />}
            </ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Popover>
  ];

  return (
    <Container>
      <Thumb thumb={thumb} />
      <InfoContainer>
        <h3>{name}</h3>
        <div className="content-icons">
          {formats.includes('AUDIO') && (
            <img src={AudioIcon} alt="icon" />
          )}
          {formats.includes('VIDEO') && (
            <img src={VideoIcon} alt="icon" />
          )}
          {formats.includes('MATERIAL') && (
            <img src={MaterialIcon} alt="icon" />
          )}
        </div>
        <Button
          width="100%"
          onClick={(e) =>
            acquired ? setAnchorEl(e.currentTarget) : onClick()
          }
        >
          {acquired ? 'Acessar conteúdo' : 'Quero participar'}
        </Button>
        {acquired && (
          <ButtonBase onClick={handleCert}>
            <img
              src={Document}
              alt="certified"
              width="20px"
              className="certified"
            />
            <label>Baixar certificado</label>
          </ButtonBase>
        )}
        {formatOptions}
      </InfoContainer>
    </Container>
  );
}

export default CourseListItem;

const Container = styled.div`
  width: 100%;
  max-width: 450px;
  height: max-content;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  background: white;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
`;

const Thumb = styled.div<{ thumb: string }>`
  max-width: 120px;
  max-height: 150px;
  width: 120px;
  height: 150px;
  border-radius: 10px;
  background: url(${(p) => p.thumb});
  background-position: center;
  background-size: cover;
`;

const InfoContainer = styled.div`
  height: max-content;
  width: 70%;
  display: -webkit-flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;

  h3 {
    text-align: left;
    font-size: 1em;
    color: #555;
    margin: 0 !important;
    padding: 0;
  }

  .content-icons {
    width: 100%;
    display: -webkit-flex;
    gap: 10px;
  }

  .certified ~ label {
    padding: 0 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
    line-height: auto;
    text-align: left;
    color: #77808b;
  }
`;
