import { UserSession } from '../modules/authentication/ts/session.interface';

export const getToken = (): string | null => {
  const token = localStorage.getItem('@token');
  return token || null;
};

export const setToken = (userSession: UserSession): void => {
  localStorage.setItem('@token', userSession.token);
};

export const isAuthenticated = (): true | false => {
  const token = localStorage.getItem('@token');
  return !!token;
};
