import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { Formik, Form } from 'formik';
import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material';
import {
  CameraAltSharp,
  KeyboardArrowLeft,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';

import Cloud from '@assets/images/cloud.png';
import TextInput from '@shared/components/Input';
import InputMask from '@shared/components/InputMask';
import Button from '@shared/components/Button';
import useProfile from '@modules/profile/hooks/useProfile';
import { Options } from '@modules/authentication/pages/Login/styles';
import { UpdateProfileData } from '@modules/profile/ts/profile.interface';

import {
  Container,
  FormContainer,
  ProfilePicContainer
} from './styles';

import profileSchema from '../../validation/profileSchema';

function UpdadeUser() {
  const history = useHistory();
  const { initialState, updateUser } = useProfile();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSelectImage = () => {
    fileInputRef.current?.click();
  };

  const handleRegister = async (values: UpdateProfileData) => {
    await updateUser(values);
    history.push('/home');
  };

  return (
    <Container>
      <img src={Cloud} alt="cloud" className="clouds" />

      {initialState ? (
        <>
          <header>
            <IconButton onClick={history.goBack}>
              <KeyboardArrowLeft fontSize="large" />
            </IconButton>
            <h3>Editar perfil</h3>
          </header>
          <FormContainer>
            <Formik
              initialValues={{
                name: initialState?.name,
                email: initialState?.email,
                birthdate: initialState?.birthdate,
                password: 'alterar senha',
                confirmPassword: 'alterar senha',
                avatar: initialState?.avatar,
                showPassword: false,
                isThirdPartyConnection: false
              }}
              validationSchema={profileSchema}
              onSubmit={({
                name,
                email,
                birthdate,
                password,
                avatar,
                isThirdPartyConnection
              }) =>
                handleRegister({
                  name,
                  email,
                  birthdate,
                  password,
                  avatar,
                  isThirdPartyConnection
                })
              }
            >
              {({
                values,
                handleChange,
                setFieldValue,
                errors,
                touched,
                isSubmitting
              }) => (
                <Form>
                  <Grid container spacing={4}>
                    <Grid item xs={12} justifyContent="center">
                      <ProfilePicContainer>
                        <Avatar
                          onClick={handleSelectImage}
                          src={
                            values.avatar
                              ? typeof values.avatar === 'string'
                                ? values.avatar
                                : URL.createObjectURL(values.avatar)
                              : undefined
                          }
                        />
                        <input
                          hidden
                          type="file"
                          name="avatar"
                          ref={fileInputRef}
                          onChange={(e) => {
                            if (e.target.files?.length) {
                              setFieldValue(
                                'avatar',
                                e.target?.files?.[0]
                              );
                            }
                          }}
                        />
                        <CameraAltSharp className="edit-icon" />
                      </ProfilePicContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextInput
                          label="Nome"
                          name="name"
                          variant="standard"
                          value={values.name}
                          onChange={handleChange}
                          helperText={touched.name && errors.name}
                          error={touched.name && !!errors.name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputMask
                          label="Data de nascimento"
                          name="birthdate"
                          format="##/##/####"
                          value={values.birthdate}
                          onChange={handleChange}
                          helperText={
                            touched.birthdate && errors.birthdate
                          }
                          error={
                            touched.birthdate && !!errors.birthdate
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextInput
                          label="Email"
                          name="email"
                          disabled={
                            initialState?.isThirdPartyConnection
                          }
                          type="email"
                          variant="standard"
                          value={values.email}
                          onChange={handleChange}
                          helperText={touched.email && errors.email}
                          error={touched.email && !!errors.email}
                        />
                      </FormControl>
                    </Grid>
                    {!initialState?.isThirdPartyConnection && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="standard">
                            <InputLabel
                              error={
                                touched.password && !!errors.password
                              }
                            >
                              Senha
                            </InputLabel>
                            <Input
                              type={
                                values.showPassword
                                  ? 'text'
                                  : 'password'
                              }
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              error={
                                touched.password && !!errors.password
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setFieldValue(
                                        'showPassword',
                                        !values.showPassword
                                      )
                                    }
                                  >
                                    {values.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <FormHelperText
                              hidden={!touched.password}
                              error={
                                touched.password && !!errors.password
                              }
                            >
                              {errors.password}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <TextInput
                              label="Confirmar Senha"
                              type={
                                values.showPassword
                                  ? 'text'
                                  : 'password'
                              }
                              name="confirmPassword"
                              variant="standard"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              error={
                                touched.confirmPassword &&
                                !!errors.confirmPassword
                              }
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <br />
                  <Options>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Atualizar perfil
                    </Button>
                  </Options>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </>
      ) : (
        <Box
          height="100vh"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="secondary" size={70} />
        </Box>
      )}
    </Container>
  );
}

export default UpdadeUser;
