import React from 'react';
import styled from 'styled-components';

import { Avatar } from '@material-ui/core';

interface MessageProps {
  avatar: string;
  name: string;
  message: string;
}
function ChatMessage({ avatar, name, message }: MessageProps) {
  return (
    <Container>
      <Avatar src={avatar} />
      <div>
        <strong>{name}</strong>
        <p>{message}</p>
      </div>
    </Container>
  );
}

export default ChatMessage;

const Container = styled.div`
  width: 100%;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-align: left;
  margin: 15px 0;

  strong {
    font: 600 14px Rubik;
    color: #c5c5c5;
  }

  p {
    font: 400 12px Rubik;
    color: #f1f1f1;
  }
`;
