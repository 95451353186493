import isAxiosError from '@helpers/isAxiosError';
import app from '@services/firebase';
import { SnapLive } from '@shared/hooks/useLiveListener';
import useTrackUserEvents from '@shared/hooks/useTrackUserEvents';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getAllCategories,
  getLiveStreams,
  getLiveStreamTokenSession,
  getStreambyCategory,
  getStreamsByDate
} from '../api';

function useLive() {
  const history = useHistory();
  const { liveEvent } = useTrackUserEvents();

  const getLives = async () => {
    try {
      const lives = await getLiveStreams();

      return lives;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getCategories = async () => {
    try {
      const categories = await getAllCategories();

      return categories;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getByFilter = async (categories: string[]) => {
    try {
      const response = await getStreambyCategory(categories);
      return response;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getByDate = async (date: string) => {
    try {
      return await getStreamsByDate(date);
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const joinLive = async ({ id: streamId }: SnapLive) => {
    try {
      liveEvent();
      const { stream, token } = await getLiveStreamTokenSession(
        streamId
      );

      if (!stream.sessionId || !token) {
        return toast.error(
          'ocorreu um erro inesperado tente novamente mais tarde'
        );
      }

      const ref = app
        .database()
        .ref(`/rooms/${stream.id}/online/123`);
      ref.set({ status: 'online' });

      history.push(`/live/${stream.sessionId}/${token}`, { stream });
    } catch (error) {
      if (isAxiosError(error)) {
        throw toast.error(error.message);
      }
    }
  };

  return {
    getLives,
    getByFilter,
    getCategories,
    joinLive,
    getByDate
  };
}

export default useLive;
