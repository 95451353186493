import {
  OTSession,
  OTSubscriber,
  preloadScript,
  OTStreams
} from 'opentok-react';
import React, { useState } from 'react';
import { CloseOutlined } from '@material-ui/icons';
import { Error } from 'opentok-react/types/opentok';
import LiveChat from '@modules/lives/components/LiveChat';
import useWatchLive from '@modules/lives/hooks/useWatchLive';
import { Footer, ScreenContainer } from '@shared/components';
import LiveSpecialist from '@modules/lives/components/LiveSpecialist';
import { Container, LiveContainer, CloseButton } from './styles';

function WatchLive() {
  const { stream, sessionId, token, exitLive } = useWatchLive();

  const [state, setState] = useState({
    error: '',
    recording: false,
    stoppedRecording: false,
    connected: false
  });

  const sessionEvents = {
    sessionConnected: () => {
      setState((state) => ({ ...state, connected: true }));
    },
    sessionDisconnected: () => {
      setState((state) => ({ ...state, connected: false }));
    },
    archiveStarted: () => {
      setState((state) => ({ ...state, recording: true }));
    },
    archiveStopped: () => {
      setState((state) => ({
        ...state,
        recording: false,
        stoppedRecording: true
      }));
    }
  };

  const onError = (error: Error) => {
    setState({ ...state, error: `Failed to connect: ${error.name}` });
  };

  return (
    <ScreenContainer>
      <Container>
        <h3>Live</h3>
        <LiveContainer>
          <LiveSpecialist
            name={stream.streamer.name}
            avatar={stream.streamer.avatar}
            views={stream.viewers}
          />
          <CloseButton onClick={exitLive}>
            <CloseOutlined />
          </CloseButton>
          <OTSession
            apiKey={process.env.REACT_APP_APIKEY_OPENTOK || ''}
            sessionId={sessionId}
            token={token}
            eventHandlers={sessionEvents}
            onError={onError}
          >
            <OTStreams>
              <OTSubscriber
                properties={{
                  preferredResolution: { width: 1280, height: 720 },
                  preferredFrameRate: 27,
                  insertMode: 'append',
                  fitMode: 'contain',
                  showControls: false,
                  height:
                    window.screen.width < 1200 ? '100vh' : '650px',
                  width:
                    window.screen.width < 1200 ? '100vw' : '1300px'
                }}
              />
            </OTStreams>
          </OTSession>
          {!stream.blockComments && <LiveChat />}
        </LiveContainer>
      </Container>
      <Footer />
    </ScreenContainer>
  );
}

export default preloadScript(WatchLive);
