import React, { useState } from 'react';
import styled from 'styled-components';

import { ButtonBase } from '@mui/material';
import { Content } from '@modules/courses/ts/course.interface';
import PdfReader from '@modules/media/components/PdfReader';

export const Container = styled.div`
  width: 100%;
  text-align: center;
  button > label {
    margin: 20px 20px 0 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5em;
    line-height: auto;
    color: #3b4c68;
  }
`;

const Policy = () => {
  const [modal, setModal] = useState({
    type: '',
    visible: false,
    url: ''
  });
  return (
    <Container>
      <PdfReader
        open={modal.visible}
        toggleOpen={() => setModal({ ...modal, visible: false })}
        content={{ url: modal.url, title: '' } as Content}
      />

      <ButtonBase
        onClick={() =>
          setModal({
            ...modal,
            type: 'Política de Privacidade',
            visible: true,
            url: 'https://ma-db-cms.s3.sa-east-1.amazonaws.com/Documentos/2021JUN23+POL%C3%8DTICA+DE+PRIVACIDADE+MEDITA+AQUI.pdf'
          })
        }
      >
        <label>Política de Privacidade</label>
      </ButtonBase>
      <ButtonBase
        onClick={() =>
          setModal({
            ...modal,
            type: 'Termos e Condições',
            visible: true,
            url: 'https://ma-db-cms.s3.sa-east-1.amazonaws.com/Documentos/2021JUN23+TERMOS+DE+USO+MEDITA+AQUI.pdf'
          })
        }
      >
        <label>Termos e Condições de Uso</label>
      </ButtonBase>
    </Container>
  );
};

export default Policy;
