import styled from 'styled-components';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import { useHistory } from 'react-router-dom';
import Button from '@shared/components/Button';
import PriceCard from '@shared/components/PriceCard';
import BackgroundImage from '@shared/components/BackgroundImage';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

import useCourse from '../../hooks/useCourse';

interface PaymentModalProps {
  courseId?: string;
  title?: string;
  description?: string;
  amount?: number;
  onClose: () => void;
  open: boolean;
}

const PaymentModal = ({
  courseId,
  open,
  onClose,
  title,
  description,
  amount
}: PaymentModalProps) => {
  const history = useHistory();
  const { acquireFreeCourse } = useCourse();

  const handleAqcuireCourse = async () => {
    if (courseId) {
      const data = await acquireFreeCourse(courseId);

      if (data) {
        toast.success('Curso adquirido');
        history.push('/home');
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BackgroundImage>
          <Container>
            <Actions>
              <IconButton onClick={onClose}>
                <HighlightOffIcon fontSize="large" />
              </IconButton>
            </Actions>
            <label>{title}</label>
            <p>{description}</p>
            <PriceCard
              setSelected={() => 0}
              name="Curso"
              amount={amount || 0}
            />
            <Button
              onClick={() =>
                amount === 0
                  ? handleAqcuireCourse()
                  : history.push(`/address/${courseId}/COURSE`)
              }
            >
              Comprar
            </Button>
          </Container>
        </BackgroundImage>
      </Modal>
    </>
  );
};

const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  max-width: 450px;
  padding: 0 20px 20px 10px;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;

  @media (min-width: 599px) {
    justify-content: center;
  }

  label:first-of-type {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 1.7em;
    line-height: auto;
    text-align: center;

    color: #3b4c68;
  }

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5em;
    line-height: auto;

    color: #3b4c68;
  }
`;

const Actions = styled.div`
  width: 100%;
  display: -webkit-flex;
  flex-direction: row;
  button:first-of-type {
    svg {
      color: #3b4c68;
    }
  }
`;

export default PaymentModal;
