import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 20px;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font: 600 18px Rubik;
  }

  select {
    max-width: 499px;
    margin-bottom: 20px;
    width: 100%;
    border: none;
    border-bottom: solid 1px #2c5e6b;
    padding: 10px;
    background-color: white;
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 1em;
    line-height: auto;
    display: -webkit-flex;
    align-items: center;
    letter-spacing: 1px;

    color: #2c5e6b;
    option {
      font-family: Rubik;
      font-style: normal;
      font-weight: 300;
      font-size: 1em;
      line-height: auto;
      display: -webkit-flex;
      align-items: center;
      letter-spacing: 1px;

      color: #2c5e6b;
    }
  }
`;

export const CountriesWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  max-width: 499px;
  border-radius: 10px;
  div.tableRows {
    width: auto;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 30px;
    align-items: center;
  }
  div.tableRows > label {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 1em;
    line-height: auto;
    display: -webkit-flex;
    align-items: center;
    letter-spacing: 1px;

    color: #2c5e6b;
  }
`;

export const CountryCard = styled.div`
  width: auto;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;

  border-bottom: solid 1px #2c5e6b;

  div {
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    img {
      width: 51px;
    }
  }

  label {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    display: -webkit-flex;
    align-items: center;
    letter-spacing: 1px;

    color: #2c5e6b;
  }
`;

export const GlobeWrapper = styled.div`
  pointer-events: none;
  iframe {
    border: none;
    height: 400px;
    width: 100%;
  }
`;
