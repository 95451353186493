import React, { ReactNode } from 'react';
import { HeaderWrapper } from '../../../assets/images';
import HeaderBar from '../Header';

import { Container, Box, Content } from './styles';

const Header = () => (
  <Box>
    <img src={HeaderWrapper} alt="header-img" />
  </Box>
);

interface ScreenContainerProps {
  children: ReactNode;
}

const ScreenContainer = ({ children }: ScreenContainerProps) => {
  return (
    <Container>
      <HeaderBar />
      <Header />
      <Content>{children}</Content>
    </Container>
  );
};

export default ScreenContainer;
