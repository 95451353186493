import * as yup from 'yup';

const paymentSchema = yup.object({
  cpf: yup
    .string()
    .required('Insira sua senha')
    .min(8, 'Sua senha precisa ter no mínimo 8 caracteres'),
  holderName: yup
    .string()
    .required('Insira o nome do Titular do cartão'),
  cardNumber: yup.string().required('Digite o numero do cartão'),
  cardCVV: yup
    .string()
    .required('Digite o código de segurança do cartão'),
  cardExpiration: yup
    .string()
    .required('Digite a data de validade do cartão'),
  paymentMethodCode: yup.string().required('Selecione'),
  terms: yup.boolean().oneOf([true], 'Aceite os termos')
});

export default paymentSchema;
