import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';

export const Container = styled(Accordion)`
  width: 100%;
  margin: 20px;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 8px 6px 11px 1px rgba(0, 0, 0, 0.12);
`;

export const Summary = styled(AccordionSummary)`
  width: 100%;
  height: 150px;
  display: -webkit-flex;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    label {
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 1.5em;
      line-height: auto;
      color: rgba(59, 76, 104, 0.8);
    }
  }
`;

export const Details = styled(AccordionDetails)`
  button {
    width: 100%;
    padding: 15px 0;
    margin: 15px 0;
    background: #ff9977;
    border-radius: 10px;
    text-align: center;

    label {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;

      color: #ffffff;
    }
  }
`;
