import styled from 'styled-components';
import CloudImage from './asset/cloud.png';

export const Gradient = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(228, 154, 175, 1) 1.56%,
    rgba(121, 116, 217, 1) 95.1%
  );
  background-size: contain;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url(${CloudImage});
  background-repeat: repeat;
  background-size: contain;
  padding: 0 10px 40px 10px;
`;
