import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(228, 154, 175, 0.9) 1.56%,
    rgba(121, 116, 217, 0.9) 95.1%
  );

  .logo {
    width: 150px;
    margin-bottom: 30px;
  }

  .clouds {
    position: absolute;
    width: 80%;
    pointer-events: none;
  }

  header {
    width: 300px;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 1.5em;
      line-height: auto;
      display: -webkit-flex;
      align-items: center;
      text-align: center;
      color: #3b4c68;
    }

    button {
      position: absolute;
      left: 0;
      svg {
        color: #3b4c68;
      }
    }

    @media (max-width: 599px) {
      width: 100%;
      padding: 10% 20px 0 20px;
    }
  }
  @media (min-height: 699px) {
    width: 100%;
    height: 100vh;
    padding: 10% 20px 0 20px;
  }
`;

export const FormContainer = styled.div`
  width: 400px;
  min-height: 700px;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 599px) {
    width: 100%;
    padding: 0 20px;
  }

  .center {
    display: grid;
    place-items: center;
  }
`;

export const ProfilePicContainer = styled.div`
  display: grid;
  place-items: center;
  position: relative;

  .edit-icon {
    position: absolute;
    bottom: 0;
    right: 38%;
    background: white;
    border-radius: 40px;
    padding: 2px;

    @media (max-width: 599px) {
      right: 40%;
      padding: 1px;
    }
  }

  .MuiAvatar-root {
    width: 80px;
    height: 80px;
    border: 3px solid white;

    @media (max-width: 599px) {
      width: 75px;
      height: 75px;
    }
  }
`;
