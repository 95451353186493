import moment from 'moment';
import styled from 'styled-components';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import Drawer from '@shared/components/Drawer';
import { Footer, MediaTrack } from '@shared/components';
import { Song } from '@modules/playlist/ts/playlist.interface';
import ScreenContainer from '@shared/components/ScreenContainer';
import { heightPercentageToDP } from '@helpers/responsiveDimensions';
import usePlaylist from '../../hooks/usePlaylist';

export const humanize = (durationMillis: number): string => {
  return moment.duration(durationMillis, 'milliseconds').humanize();
};

const Plalist = () => {
  const { playlist, setNowPlaying } = usePlaylist();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isSong(song: any): song is Song {
    return 'people_listened' in song;
  }

  return (
    <Drawer>
      <ScreenContainer>
        <Container>
          <h3>Playlist</h3>
          {playlist.length ? (
            playlist.map((song) => {
              return (
                isSong(song) && (
                  <MediaTrack
                    key={song.id}
                    onClick={() => setNowPlaying(song, 'SONG')}
                    title={song.title}
                    duration={humanize(song.duration_millis)}
                    listened={song?.people_listened}
                  />
                )
              );
            })
          ) : (
            <Box
              height={heightPercentageToDP(60)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="secondary" />
            </Box>
          )}
        </Container>
        <Footer />
      </ScreenContainer>
    </Drawer>
  );
};

export default Plalist;

const Container = styled.div`
  width: 100%;
  padding: 0 20px;

  h3 {
    font: 600 18px Rubik;
  }

  @media (max-width: 599px) {
    margin-top: 0;
  }
`;
