import { Box } from '@mui/system';
import {
  Container,
  Background,
  Icon,
  Button,
  Title,
  Wrapper
} from './styles';

interface CardBottomProps {
  title: string;
  background: string;
  color: string;
  radius?: boolean;
  icon: string;
  onClick: () => void;
}

const BottomCard = ({
  background,
  title,
  icon,
  radius,
  color,
  onClick
}: CardBottomProps) => {
  return (
    <Container>
      <Background radius={radius} image={background}>
        <Wrapper color={color}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="20px"
          >
            <Icon width="54.74px" height="54.15px" src={icon} />
            <Title>{title}</Title>
          </Box>
          <Box>
            <Button onClick={onClick}>Entrar Agora</Button>
          </Box>
        </Wrapper>
      </Background>
    </Container>
  );
};

export default BottomCard;
