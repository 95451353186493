import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 80px;

  @media (max-width: 599px) {
    padding: none;
  }
`;

export const LiveContainer = styled.div`
  width: 100%;
  height: 650px;
  border-radius: 20px;
  position: relative;
  background-color: #1a1a1a;
  overflow: hidden;

  h3 {
    margin: 20px 0;
  }

  @media (max-width: 799px) {
    position: fixed;
    z-index: 10000;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
  color: white;
  fill: white;

  display: none;

  @media (max-width: 799px) {
    display: block;
  }
`;
