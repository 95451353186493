import isAxiosError from '@helpers/isAxiosError';
import { toast } from 'react-toastify';
import {
  confirmResetCode,
  requestPasswordReset,
  submitNewPassword
} from '../api';

const usePassword = () => {
  return {
    requestReset: async (email: string) => {
      try {
        const data = await requestPasswordReset(
          email.toLowerCase().trim()
        );

        if (data) {
          return true;
        }
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    },
    confirmCode: async (email: string, code: string) => {
      try {
        const data = await confirmResetCode(
          email.toLowerCase().trim(),
          code
        );

        if (data) {
          return true;
        }
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    },
    submitPassword: async (
      email: string,
      code: string,
      newPassword: string
    ) => {
      try {
        const data = await submitNewPassword(
          email.toLowerCase().trim(),
          code,
          newPassword
        );
        if (data) {
          toast.success('Senha alterada com sucesso');
          return true;
        }
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    }
  };
};

export default usePassword;
