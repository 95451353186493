import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  max-width: 400px;
  height: 100%;
  display: -webkit-flex;
  gap: 20px;
  padding: 0 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 599px) {
    justify-content: center;
  }

  :not(h1) {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: auto;
    text-align: center;
    color: #3b4c68;
    text-align: left;
  }

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: auto;
    color: #3b4c68;
    text-align: left;
  }

  div.permissions {
    width: 100%;

    div {
      display: -webkit-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      svg {
        color: #3b4c68;
      }
    }
  }
`;

export const Permissions = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: auto;
  text-align: center;
  color: #3b4c68;
  margin: 25px 0;
  text-align: left;
`;

export const Info = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: auto;
  text-align: center;
  color: #3b4c68;
  margin: 5px;
`;

export const Benefit = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: auto;
  text-align: center;
  color: #3b4c68;
  margin-bottom: 25px;
`;
