import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background-color: red;
  ::-webkit-scrollbar {
    width: 0px;
  }
  /* max-width: 1440px; */
  background: linear-gradient(
    180deg,
    rgba(228, 154, 175, 0.9) 1.56%,
    rgba(121, 116, 217, 0.9) 95.1%
  );
`;

interface PageWrapperProps {
  progress: number;
}

export const PageWrapper = styled.div<PageWrapperProps>`
  @media (max-width: 425px) {
    position: fixed;
    top: 0;
  }

  overflow: hidden;
  width: 100%;
  height: 100vh;
  transition: transform 500ms, border-radius 500ms;
  transform: ${({ progress }) =>
    progress ? 'scale(0.8) translateX(50%)' : 'none'};
  border-radius: ${({ progress }) => (progress ? 15 : 0)}px;
`;

export const MenuWrapper = styled.div`
  display: none;
  @media (max-width: 425px) {
    display: initial;
  }
`;

export const Link = styled.button`
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: -webkit-flex;
  background-color: transparent;
  border: none;
  margin-left: 15px;
  text-decoration: none;
  align-items: center;
  color: #3b4c68;
`;
