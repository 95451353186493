import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@material-ui/icons';
import { ptBR } from 'date-fns/locale';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { Box } from '@mui/system';
import { DesktopDatePicker } from '@mui/lab';

import useLive from '@modules/lives/hooks/useLive';
import { useUser } from '@contexts/AuthContext';
import { ScreenContainer, Footer, Drawer } from '@shared/components';
import LivePreview from '@modules/lives/components/LivePreview';
import CategoryIcon from '@modules/lives/components/CategoryIcon';
import useTrackUserEvents from '@shared/hooks/useTrackUserEvents';
import LiveReplay from '@modules/lives/components/LiveReplay';
import LiveItem from '@modules/lives/components/LiveItem';
import LiveStream, {
  LiveStreamCategory
} from '@modules/lives/ts/stream.interface';
import useLiveListener from '@shared/hooks/useLiveListener';

import {
  Container,
  CategoriesList,
  LivesListContainer,
  LiveStreamsContainer
} from './styles';

function LivesList() {
  const history = useHistory();
  const { subscription } = useUser();
  const { liveEvent } = useTrackUserEvents();
  const { streams, hasLive } = useLiveListener();
  const [liveStream, setLiveStream] = useState<LiveStream | null>(
    null
  );
  const { getCategories, getByFilter, getByDate, joinLive } =
    useLive();
  const [liveList, setLiveList] = useState<
    LiveStream[] | undefined
  >();
  const [categories, setCategories] = useState<
    LiveStreamCategory[] | undefined
  >();
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | null
  >(null);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleGetInfo = async () => {
    setCategories(await getCategories());
  };

  const handleFilterByCategory = async () => {
    const category = selectedCategoryId ? [selectedCategoryId] : [];
    setLiveList(await getByFilter(category));
  };

  const handleFilterByDate = async () => {
    if (selectedDate) {
      setLiveList(
        await getByDate(moment(selectedDate).format('yyyy-MM-DD'))
      );
    }
  };

  const handleCleanFilters = async () => {
    setSelectedCategoryId(null);
    setSelectedDate(null);
    handleFilterByCategory();
  };

  const handleSetLivePastWatch = (stream: LiveStream) => {
    const duration = moment.duration(
      moment().diff(stream.created_at)
    );

    const hours = duration.asHours();

    if (subscription || (stream.onlyFor24Hrs && hours <= 24)) {
      liveEvent();
      setLiveStream(stream);
      return;
    }

    toast.info(
      'Para assistir todas as lives gravadas faça a sua assinatura.',
      { closeButton: true }
    );
    history.push('/subscription');
  };

  useEffect(() => {
    handleGetInfo();
  }, []);

  useEffect(() => {
    handleFilterByCategory();
  }, [selectedCategoryId]);

  useEffect(() => {
    handleFilterByDate();
  }, [selectedDate]);

  return (
    <Drawer>
      <ScreenContainer>
        <Container>
          <h3>Lives</h3>
          <LiveStreamsContainer>
            {streams.map((live) => (
              <LivePreview
                thumb={live.thumb}
                onClick={() => joinLive(live)}
              />
            ))}
            {!hasLive && <h4>Não há nenhuma live listada</h4>}
          </LiveStreamsContainer>

          <br />
          <br />
          <br />

          <h3 className="live-section-label">
            Selecione o tema ou a data das lives que já aconteceram
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptBR}
            >
              <DesktopDatePicker
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue);
                }}
                renderInput={({ inputRef, InputProps }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {InputProps?.endAdornment}
                    <div ref={inputRef} />
                  </Box>
                )}
              />
            </LocalizationProvider>
          </h3>
          {(selectedCategoryId || selectedDate) && (
            <span
              onClick={() => handleCleanFilters()}
              className="remove-filter"
            >
              <DeleteOutlined /> Remover filtro
            </span>
          )}
          <CategoriesList>
            {categories?.map((item: LiveStreamCategory) => (
              <CategoryIcon
                key={item.id}
                title={item.name}
                image={item.background_url}
                selected={item.id === selectedCategoryId}
                onClick={() => setSelectedCategoryId(item.id)}
              />
            ))}
          </CategoriesList>

          <br />
          <br />
          <br />

          <h3>Todas as Lives</h3>
          <LivesListContainer>
            {liveList?.map((live) => (
              <>
                <LiveItem
                  onClick={() => handleSetLivePastWatch(live)}
                  title={live.title}
                  views={live.viewers}
                  url={live.thumb}
                />
              </>
            ))}
            <LiveReplay
              live={liveStream}
              open={!!liveStream?.id}
              onClose={() => setLiveStream(null)}
            />
          </LivesListContainer>
        </Container>
        <Footer />
      </ScreenContainer>
    </Drawer>
  );
}

export default LivesList;
