import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

// Initialize Firebase
const firebaseConfig = {
  appId: '1:135837502503:web:0dc2c1a2e9fc8c43135202',
  apiKey: 'AIzaSyBGet4THM3rAnuc6JR6XWucJLpA1RisIHo',
  projectId: 'books-275121',
  authDomain: 'books-275121.firebaseapp.com',
  storageBucket: 'books-275121.appspot.com',
  messagingSenderId: '135837502503',
  measurementId: 'G-YHMHST20Q5',
  databaseURL: 'https://books-275121-default-rtdb.firebaseio.com/'
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
