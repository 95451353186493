import { Typography } from '@mui/material';
import { Link as RouterLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 425px) {
    height: max-content;
  }

  display: -webkit-flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: max-content;
  padding: 20px 0 0 0;
  background: linear-gradient(
    180deg,
    rgba(228, 154, 175, 0.9) 10%,
    rgb(198, 154, 228) 100%
  );
`;

export const Background = styled.div<{ image: string }>`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  height: max-content;
  width: 100%;
  display: -webkit-flex;
  justify-content: center;
  align-self: center;
  resize: contain;
`;

export const NavSession = styled.div`
  display: -webkit-flex;
  overflow: visible;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Nav = styled.div`
  display: -webkit-flex;
  align-items: flex-start;
  justify-content: space-around;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Image = styled.img`
  height: 7%;
  width: 7%;
  display: none;
  @media (min-width: 426px) {
    display: inherit;
  }
`;

interface LinkProps extends NavLinkProps {
  selected: boolean;
}

export const Link = styled(RouterLink)<LinkProps>`
  margin: 0px 20px 5px 20px;
  padding-bottom: 15px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 1em;
  display: -webkit-flex;
  outline: none;
  border: none;
  text-decoration: none;
  border-bottom: ${({ selected }) =>
    selected ? 'solid 2px #fff' : 'none'};
  align-items: center;
  color: #f5f9ff;
`;

export const NavWrapper = styled.div`
  width: 90%;
  max-width: 1440px;
  z-index: 2;
`;

export const TitleSession = styled.div`
  align-items: flex-start;
  flex-direction: column;
  display: none;
  max-width: 350px;
  @media (min-width: 1024px) {
    display: inherit;
  }
`;

export const Title = styled(Typography)`
  font-family: Rubik;
  font-size: 1.2em;
  max-width: 100%;
  font-style: normal;
  color: #f5f9ff;
  i {
    font-weight: 600;
  }
`;

export const Message = styled.h2`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1em;
  max-width: 420px;
  line-height: 20px;
  color: #f5f9ff;
  @media (max-width: 1023px) {
    display: none;
  }
`;
