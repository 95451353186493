class LocalStorage {
  private keyParam;

  constructor(key: string) {
    this.keyParam = key;
  }

  public getItem(): string | null {
    return localStorage.getItem(this.keyParam);
  }

  public setItem(value: string): void {
    localStorage.setItem(this.keyParam, value);
  }
}

const useLocalStorage = (key: string) => new LocalStorage(key);
export default useLocalStorage;
