import isAxiosError from '@helpers/isAxiosError';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllCategories } from '../api';
import Category from '../ts/category.interface';

const useCategories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  useEffect(() => {
    (async () => {
      try {
        const response = await getAllCategories();
        setCategories(response);
      } catch (error) {
        // eslint-disable-next-line no-console
        if (isAxiosError(error)) {
          toast.error(
            error.message ||
              'Não foi possível pesquisar as categorias de meditação'
          );
        }
      }
    })();
  }, []);
  return {
    categories
  };
};
export default useCategories;
