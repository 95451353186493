import styled from 'styled-components';

export const Container = styled.div`
  min-height: 280px;
  margin-top: 100px;
  width: 100%;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 90%;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  @media (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
  }
  gap: 30px;
  margin-bottom: 40px;
  padding: 10px 0;
  border-bottom: 1px solid #8b9cfe;
`;

export const Label = styled.a`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: -webkit-flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 150%;
  color: #77808b;
`;

export const Col = styled.div`
  display: -webkit-flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
`;

export const Rol = styled.div``;
