import React, { ReactNode, useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { Tab, Typography, CircularProgress } from '@mui/material';
import { Drawer, Footer, ScreenContainer } from '@shared/components';

import { Course } from '@modules/courses/ts/course.interface';
import useCourse from '@modules/courses/hooks/useCourse';
import PaymentModal from '../../components/PaymentModal';
import CourseListItem from '../../components/CourseListItem';

import {
  Container,
  Content,
  TabsContainer,
  CourseList
} from './styles';

function CoursesList() {
  const { getAcquiredCourses, getMentorships, getCourses } =
    useCourse();
  const [courses, setCourses] = useState<Course[] | undefined>();
  const [course, setCourse] = useState<Course | null>(null);
  const [mentorships, setMentorships] = useState<
    Course[] | undefined
  >();

  const [acquiredCourses, setAcquiredCourses] = useState<
    Course[] | undefined
  >();

  const [currentTab, setCurrentTab] = useState<1 | 2 | 3>(1);

  const handleGetInfo = async () => {
    setCourses(await getCourses());
    setMentorships(await getMentorships());
    setAcquiredCourses(await getAcquiredCourses());
  };

  useEffect(() => {
    handleGetInfo();
  }, []);

  const hasAcquired = (itemId: string) =>
    acquiredCourses?.some(({ id }) => id === itemId);

  function TabPanel(props: {
    children: ReactNode;
    index: number;
    value: number;
  }) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <Drawer>
      <ScreenContainer>
        <Container>
          <Content>
            <h3>Cursos e Mentorias</h3>
            <TabsContainer
              value={currentTab}
              onChange={(e, newValue: 1 | 2 | 3) =>
                setCurrentTab(newValue)
              }
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab value={1} label="Cursos" />
              <Tab value={2} label="Mentorias" />
              <Tab value={3} label="Adquiridos" />
            </TabsContainer>
            <TabPanel value={currentTab} index={1}>
              <CourseList>
                {courses?.map((item) => (
                  <CourseListItem
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    onClick={() => setCourse(item)}
                    thumb={item.thumb}
                    formats={item.formats}
                    acquired={hasAcquired(item.id)}
                  />
                ))}
                {courses?.length === 0 && (
                  <Box width="100%">Sem cursos disponíveis</Box>
                )}
                {!courses && (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    padding="30px 0"
                  >
                    <CircularProgress color="secondary" />
                  </Box>
                )}
              </CourseList>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <CourseList>
                {mentorships?.map((item) => (
                  <CourseListItem
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    thumb={item.thumb}
                    formats={item.formats}
                    onClick={() => setCourse(item)}
                    acquired={hasAcquired(item.id)}
                  />
                ))}
                {mentorships?.length === 0 && (
                  <Box width="100%">Sem cursos disponíveis</Box>
                )}
                {!mentorships && (
                  <Box width="100%" justifyContent="center">
                    <CircularProgress color="secondary" />
                  </Box>
                )}
              </CourseList>
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <CourseList>
                {acquiredCourses?.map((item) => (
                  <CourseListItem
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    thumb={item.thumb}
                    formats={item.formats}
                    onClick={() => 0}
                    acquired
                  />
                ))}
                {acquiredCourses?.length === 0 && (
                  <Box width="100%">Sem cursos disponíveis</Box>
                )}
                {!acquiredCourses && (
                  <Box width="100%">
                    <CircularProgress color="secondary" />
                  </Box>
                )}
              </CourseList>
            </TabPanel>
          </Content>
        </Container>

        <PaymentModal
          courseId={course?.id}
          amount={course?.amount}
          description={course?.description}
          title={course?.name}
          open={!!course?.id}
          onClose={() => setCourse(null)}
        />

        <Footer />
      </ScreenContainer>
    </Drawer>
  );
}

export default CoursesList;
