import { useEffect } from 'react';
import { usePlayer } from '@contexts/MusicPlayerContent';
import useTrackUserEvents from '@shared/hooks/useTrackUserEvents';
import { getPlaylist } from '../api';

const usePlaylist = () => {
  const { setPlaylist, playlist, setNowPlaying } = usePlayer();
  const { playlistEvent } = useTrackUserEvents();

  useEffect(() => {
    (async () => {
      playlistEvent();
      const response = await getPlaylist();
      setPlaylist(response.data[0].songs);
    })();
  }, []);

  return {
    playlist,
    setPlaylist,
    setNowPlaying
  };
};

export default usePlaylist;
