import React from 'react';
import { PlayArrowSharp } from '@material-ui/icons';
import styled from 'styled-components';
import Colors from '@constants/Colors';

import BgDefaultImg from '@assets/images/live-default.png';
import {
  heightPercentageToDP,
  widthPercentageToDP
} from '@helpers/responsiveDimensions';

function LivePreview({
  thumb,
  onClick
}: {
  thumb: string;
  onClick?: () => void;
}) {
  const background = thumb === '' || !thumb ? BgDefaultImg : thumb;
  return (
    <Container onClick={onClick} background={background}>
      <div>
        <PlayButton>
          <PlayArrowSharp />
        </PlayButton>
      </div>
    </Container>
  );
}

export default LivePreview;

const Container = styled.div<{ background: string }>`
  div {
    position: relative;
    width: ${widthPercentageToDP(70)}px;
    height: ${heightPercentageToDP(40)}px;
    max-width: 600px;
    border-radius: 20px;
    border: 2px solid ${Colors.palette.primary};
    background-image: url(${(p) => p.background});
    background-size: cover;
    background-position: center;

    @media (max-width: 599px) {
      height: 200px;
      width: ${widthPercentageToDP(80)}px;
    }
  }
`;

const PlayButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: ${Colors.palette.primary};
  color: white;
  padding: 5px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  outline: 0;
  border: 0;
  display: grid;
  place-items: center;
`;
