import styled from 'styled-components';
import Colors from '@constants/Colors';

export const Container = styled.div`
  display: -webkit-flex;
  justify-content: space-around;
  flex-direction: row;

  @media (max-width: 350px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content {
    display: -webkit-flex;
    max-width: 50%;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 350px) {
      max-width: 100%;
      text-align: center;
      gap: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;
export const Wrapper = styled.div`
  height: 400px;
  width: 400px;

  @media (max-width: 599px) {
    height: 200px;
    width: 100%;
  }

  @media (min-width: 768px) {
    height: 100%;
    width: 100%;
    max-height: 600px;
    max-width: 600px;
  }
`;

export const Label = styled.label`
  font-family: Rubik;
  padding: 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  line-height: auto;
  color: #77808b;

  @media (max-width: 599px) {
    font-size: 1em;
  }

  @media (min-width: 768px) {
    font-size: 1.6em;
  }
`;

export const Number = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: auto;
  color: ${Colors.palette.primary};
`;

export const Message = styled.label`
  display: none;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: auto;
  color: #7d7d7d;
  @media (min-width: 768px) {
    display: initial;
  }
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

export const Button = styled.button`
  border: solid 1px ${Colors.palette.primary};
  padding: 7px 12px;
  border-radius: 10px;
  font-family: Rubik;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
  color: ${Colors.palette.primary};
`;
