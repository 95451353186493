import React, { useEffect, useState } from 'react';
import moment from 'moment';

import useSchedule from '@modules/schedule/hooks/useSchedule';
import { ScheduleEvent } from '@modules/schedule/ts/reminder.interface';
import Drawer from '@shared/components/Drawer';
import Footer from '@shared/components/Footer';
import ScreenContainer from '@shared/components/ScreenContainer';

import {
  DateNavigator,
  MonthView,
  Scheduler,
  Toolbar
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  AppointmentModel,
  ViewState
} from '@devexpress/dx-react-scheduler';

import EvenetListItem from '../../components/EventListItem';
import {
  Container,
  EventsList,
  StyledDayScaleComponent,
  StyledMonthSchedule,
  StyledTableComponent,
  StyledTableRow,
  StyledTimeTableCell,
  StyledScaleRow
} from './styles';

function Schedule() {
  const {
    getScheduleReminders,
    addEventReminder,
    removeEventReminder
  } = useSchedule();
  const [events, setEvents] = useState<ScheduleEvent[]>();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const hasEvent = (date: Date) => {
    return !!schedulerData?.find((item) => {
      return (
        item.startDate.toString().split('T')[0] ===
        date.toISOString().split('T')[0]
      );
    });
  };

  const toggleConfirmation = async (
    hasConfirmed: boolean,
    id: string,
    payload: {
      title: string;
      start: string;
      end: string;
      provider_id: string;
      calendar_id: string;
    }
  ) => {
    if (hasConfirmed) {
      await removeEventReminder(id);
    } else {
      await addEventReminder(payload);
    }

    await handleGetEvents();
  };

  const handleGetEvents = async () => {
    setEvents(await getScheduleReminders());
  };

  useEffect(() => {
    handleGetEvents();
  }, []);

  const DayScaleLabel = ({
    ...rest
  }: MonthView.DayScaleCellProps) => (
    <StyledDayScaleComponent {...rest} />
  );

  const DayCellComponent = ({
    startDate,
    today,
    ...rest
  }: MonthView.TimeTableCellProps) => {
    return (
      <StyledTimeTableCell
        {...rest}
        startDate={startDate}
        today={today}
        hasEvent={hasEvent(startDate)}
        isSelected={moment(startDate).isSame(currentDate)}
        onClick={() => setCurrentDate(new Date(startDate))}
      />
    );
  };

  const DayRowComponent = ({ ...rest }: MonthView.RowProps) => (
    <StyledTableRow {...rest} />
  );

  const TableLayoutComponent = ({
    ...rest
  }: MonthView.TimeTableLayoutProps) => (
    <StyledTableComponent {...rest} />
  );

  const DayScaleRow = ({ ...rest }: MonthView.RowProps) => (
    <StyledScaleRow {...rest} />
  );

  const schedulerData: AppointmentModel[] = [];

  events?.forEach((item) =>
    item.reminders.map((reminder) =>
      schedulerData.push({
        startDate: reminder.start,
        endDate: reminder.end
      })
    )
  );

  return (
    <Drawer>
      <ScreenContainer>
        <Container>
          <h2>Agenda do Medita.Aqui</h2>
          <StyledMonthSchedule>
            <Scheduler locale="pt-BR" data={schedulerData}>
              <ViewState
                currentDate={currentDate}
                onCurrentDateChange={(date) => setCurrentDate(date)}
              />
              <Toolbar />
              <DateNavigator />
              <MonthView
                dayScaleRowComponent={DayScaleRow}
                dayScaleCellComponent={DayScaleLabel}
                timeTableLayoutComponent={TableLayoutComponent}
                timeTableCellComponent={DayCellComponent}
                timeTableRowComponent={DayRowComponent}
              />
            </Scheduler>
          </StyledMonthSchedule>

          <EventsList>
            <h1>Eventos do dia {currentDate.toLocaleDateString()}</h1>
            {events?.map((item) => {
              return (
                moment(item.date).isSame(currentDate) &&
                item.reminders.map((reminder) => {
                  return (
                    <EvenetListItem
                      callback={() =>
                        toggleConfirmation(
                          reminder.hasConfirmed,
                          reminder.id,
                          {
                            title: reminder.title,
                            start: reminder.start,
                            end: reminder.end,
                            calendar_id: reminder.id,
                            provider_id: reminder.specialist_id
                          }
                        )
                      }
                      hasConfirmed={reminder.hasConfirmed}
                      title={reminder.title}
                      date={reminder.start}
                      description={reminder.message}
                    />
                  );
                })
              );
            })}
          </EventsList>
        </Container>

        <Footer />
      </ScreenContainer>
    </Drawer>
  );
}

export default Schedule;
