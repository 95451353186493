import Draggable from 'react-draggable';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import ProgressBar from 'react-customizable-progressbar';
import ReactPlayer from 'react-player';

import VolumeUp from '@mui/icons-material/VolumeUp';

import { Box } from '@mui/system';
import { IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { widthPercentageToDP } from '@helpers/responsiveDimensions';

import Colors from '@constants/Colors';
import { usePlayer } from '@contexts/MusicPlayerContent';
import { millisToMinutes } from '@helpers/convertTime';
import useTrackPlayer from '@shared/hooks/useTrackPlayer';

import ActionButton from './components/ActionButton';

import { Play, Forward, Previus, Pause } from './icons';
import {
  Container,
  PlayerContent,
  Title,
  ProgressWrapper,
  ActionWrapper,
  VolumeWrapper
} from './styles';

function GlobalMusicPlayer() {
  const { isPlayerActive, togglePlayer, currentSong, playerType } =
    usePlayer();
  const {
    track,
    playerRef,
    skipToNext,
    skipToPrevius,
    pause,
    handleTime,
    setTrack,
    handleVolume,
    togglePlaying
  } = useTrackPlayer();

  return (
    <Draggable disabled allowAnyClick enableUserSelectHack>
      <Container hidden={!isPlayerActive}>
        <PlayerContent>
          <div className="close-wrapper">
            <IconButton
              className="close"
              onClick={() => {
                pause();
                togglePlayer(false);
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
          <VolumeWrapper>
            <ProgressBar
              // min={0}
              // max={1.0}
              // step={0.1}
              // value={track.volume}
              // onChange={(value: number) => handleVolume(value)}
              cut={180}
              steps={100}
              rotate={180}
              pointerStrokeWidth={7}
              progress={track.volume * 100}
              className="volume-slider"
              pointerFillColor={Colors.palette.background.primary}
              pointerStrokeColor={Colors.palette.primary}
              strokeWidth={7}
              pointerRadius={7}
              strokeColor={Colors.palette.primary}
              trackStrokeWidth={7}
              radius={widthPercentageToDP(42)}
            />
            <div className="action">
              <IconButton onClick={() => handleVolume('less')}>
                <VolumeDown color="secondary" />
              </IconButton>
              <IconButton onClick={() => handleVolume('plus')}>
                <VolumeUp color="secondary" />
              </IconButton>
            </div>
          </VolumeWrapper>

          <Title>{currentSong?.title}</Title>

          <Box display="none">
            <ReactPlayer
              config={{ file: { forceAudio: true } }}
              showSkipControls={false}
              showJumpControls={false}
              onProgress={({ playedSeconds, loadedSeconds }) => {
                const isBuffingLoaded =
                  loadedSeconds >= playedSeconds;

                const progress =
                  Number(playedSeconds.toFixed(0)) * 1000;

                setTrack({ ...track, progress, isBuffingLoaded });
              }}
              showFilledVolume={false}
              showFilledProgress={false}
              ref={playerRef}
              playing={track.playing}
              volumeJumpStep={0.1}
              volume={track.volume}
              url={currentSong?.url}
            />
          </Box>

          <ProgressWrapper>
            <Slider
              color="secondary"
              min={0}
              value={track.progress}
              step={1000}
              max={currentSong?.duration_millis}
              onChange={(_, value) => handleTime(value)}
            />
            <div>
              <label>{millisToMinutes(track.progress)}</label>
              <label>
                {millisToMinutes(currentSong?.duration_millis)}
              </label>
            </div>
          </ProgressWrapper>

          <ActionWrapper>
            <div>
              {playerType !== 'MEDITATION' && (
                <ActionButton
                  onClick={skipToPrevius}
                  icon={Previus}
                />
              )}
              <ActionButton
                onClick={togglePlaying}
                loading={!track.isBuffingLoaded}
                icon={track.playing ? Pause : Play}
              />
              {playerType !== 'MEDITATION' && (
                <ActionButton onClick={skipToNext} icon={Forward} />
              )}
            </div>
          </ActionWrapper>
        </PlayerContent>
      </Container>
    </Draggable>
  );
}

export default GlobalMusicPlayer;
