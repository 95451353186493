import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material';
import useInterval from '@helpers/useInterval';
import Button from '@shared/components/Button';
import TextInput from '@shared/components/Input';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { Box } from '@mui/system';
import Cloud from '@assets/images/clouds.png';
import AppleIcon from '@assets/icons/Apple.png';
import Logo from '@assets/images/name_logo.svg';
import GoogleIcon from '@assets/icons/google.png';
import useSignInWithGoogle from '@modules/authentication/hooks/useGoogleOAuth';
import useSignInWithApple from '@modules/authentication/hooks/useAppleOAuth';
import ResetPasswordModal from '@modules/authentication/components/ResetPasswordModal';

import {
  Container,
  Options,
  FormContainer,
  HintError
} from './styles';

import useLogin from '../../hooks/useLogin';
import loginSchema from '../../validation/loginSchema';

function Login() {
  const [current, setCurrent] = useState(0);
  const [content] = useState([
    {
      title: '...inspire...',
      description:
        'É possível viver em equilíbrio, na sua melhor versão, todos os dias. Desenvolva o seu poder pessoal para conquistar a abundância em tudo na sua vida! A conexão com Deus, com o Universo, nos permitem tudo isso e, a respiração... a respiração é um caminho imediato!'
    },
    {
      title: '...expire...',
      description:
        'É possível viver em equilíbrio, na sua melhor versão, todos os dias. Desenvolva o seu poder pessoal para conquistar a abundância em tudo na sua vida! A conexão com Deus, com o Universo, nos permitem tudo isso e, a respiração... a respiração é um caminho imediato!'
    },
    {
      title: '...esteja presente...',
      description:
        'É possível viver em equilíbrio, na sua melhor versão, todos os dias. Desenvolva o seu poder pessoal para conquistar a abundância em tudo na sua vida! A conexão com Deus, com o Universo, nos permitem tudo isso e, a respiração... a respiração é um caminho imediato!'
    }
  ]);

  const titleRef = useRef<HTMLHeadingElement | null>(null);

  useInterval(() => {
    setCurrent(current < content.length - 1 ? current + 1 : 0);
  }, 5000);

  const { startSession } = useLogin();
  const { signWithGoogle } = useSignInWithGoogle();
  const { signWithApple } = useSignInWithApple();

  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);

  const handleLogin = async (data: {
    email: string;
    password: string;
  }) => {
    await startSession(data);
  };

  return (
    <Container>
      <img src={Cloud} alt="cloud" className="clouds" />

      <img src={Logo} alt="MeditaAqui" className="logo" />
      {loginOpen ? (
        <>
          <Formik
            initialValues={{
              email: '',
              password: '',
              showPassword: false
            }}
            validationSchema={loginSchema}
            onSubmit={({ email, password }) =>
              handleLogin({ email, password })
            }
          >
            {({
              values,
              handleChange,
              setFieldValue,
              isSubmitting,
              errors,
              touched
            }) => (
              <FormContainer>
                <h2>Login</h2>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextInput
                        label="Email"
                        name="email"
                        type="email"
                        variant="standard"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <HintError
                      hidden={!touched.email}
                      // error={touched.email && !!errors.email}
                    >
                      {errors.email}
                    </HintError>
                  </Grid>

                  <Grid item xs={12} lg={12} xl={12}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel
                        error={touched.password && !!errors.password}
                      >
                        Senha
                      </InputLabel>
                      <Input
                        type={
                          values.showPassword ? 'text' : 'password'
                        }
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && !!errors.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setFieldValue(
                                  'showPassword',
                                  !values.showPassword
                                )
                              }
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <HintError hidden={!touched.password}>
                      {errors.password}
                    </HintError>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <span onClick={() => setIsResetModalOpen(true)}>
                      Esqueceu a senha?
                    </span>
                  </Grid>

                  <ResetPasswordModal
                    open={isResetModalOpen}
                    toggleOpen={() =>
                      setIsResetModalOpen((state) => !state)
                    }
                  />
                </Grid>

                <Options>
                  <Button loading={isSubmitting} type="submit">
                    Entrar
                  </Button>
                </Options>
              </FormContainer>
            )}
          </Formik>
          <Options>
            <Button onClick={signWithApple}>
              <img src={AppleIcon} alt="apple" />
              Entrar via Apple
            </Button>
            <Button onClick={signWithGoogle}>
              <img src={GoogleIcon} alt="google" />
              Entrar via Google
            </Button>
          </Options>
        </>
      ) : (
        <>
          <h3 ref={titleRef} id="title">
            {content[current].title}
          </h3>
          <p>
            É possível viver em equilíbrio, na sua melhor Versão,
            todos os dias. Desenvolva o seu poder pessoal para
            conquistar a abundância em tudo na sua vida! A conexão com
            Deus, com o Universo, nos permitem tudo isso e, a
            respiração... a respiração é um caminho imediato!
          </p>

          <Options>
            <Button onClick={() => setLoginOpen(true)}>Entrar</Button>
            <Button onClick={signWithApple}>
              <img src={AppleIcon} alt="apple" />
              Entrar via Apple
            </Button>
            <Button onClick={signWithGoogle}>
              <img src={GoogleIcon} alt="google" />
              Entrar via Google
            </Button>
          </Options>
        </>
      )}

      <span>
        Não tem uma conta? <Link to="/signup">Inscreva-se</Link>
      </span>
    </Container>
  );
}

export default Login;
