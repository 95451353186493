import { useEffect, useState } from 'react';
import ScreenContainer from '@shared/components/ScreenContainer';
import PdfReader from '@modules/media/components/PdfReader';
import MaterialListItem from '@modules/media/components/MaterialListItem';
import { Footer } from '@shared/components';
import useContent from '@modules/media/hooks/useContent';

import { Content } from '@modules/courses/ts/course.interface';
import useQuery from '@helpers/getQueryParams';
import { Container, MaterialsList } from './styles';

function MaterialList() {
  const [content, setContent] = useState<Content[] | undefined>();
  const [openContent, setOpenContent] = useState<Content | null>(
    null
  );
  const { getCourseContent } = useContent();
  const query = useQuery();

  const handleGetCourseContent = async () => {
    const id = query.get('id');
    if (id) {
      setContent(await getCourseContent(id, 'MATERIAL'));
    }
  };

  useEffect(() => {
    handleGetCourseContent();
  }, []);

  return (
    <ScreenContainer>
      <Container>
        <h3>Materiais</h3>
        <MaterialsList>
          {content?.map((item) => (
            <MaterialListItem
              isActive
              title={item.title}
              description={item.description}
              onSelect={() => setOpenContent(item)}
            />
          ))}
        </MaterialsList>

        <PdfReader
          open={!!openContent}
          content={openContent}
          toggleOpen={() => setOpenContent(null)}
        />
      </Container>
      <Footer />
    </ScreenContainer>
  );
}

export default MaterialList;
