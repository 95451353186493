import isAxiosError from '@helpers/isAxiosError';
import app from '@services/firebase';
import firebase from 'firebase/app';
import { toast } from 'react-toastify';
import useLogin from './useLogin';
import useSignUp from './useSignUp';

const useSignInWithApple = () => {
  const { startSession } = useLogin();
  const { createUser } = useSignUp();

  const signWithApple = async () => {
    try {
      const provider = new firebase.auth.OAuthProvider('apple.com');

      const { user, additionalUserInfo } = await app
        .auth()
        .signInWithPopup(provider);

      if (additionalUserInfo?.isNewUser && user && user.email) {
        await createUser({
          avatar: user.photoURL || '',
          email: user.email,
          name: user.displayName || 'google',
          password: user.uid,
          isThirdPartyConnection: true,
          birthdate: '1990-01-01'
        });
      } else if (
        !additionalUserInfo?.isNewUser &&
        user &&
        user.email &&
        user.uid
      ) {
        await startSession({
          email: user.email,
          password: user.uid
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return { signWithApple };
};
export default useSignInWithApple;
