import styled from 'styled-components';
import Colors from '@constants/Colors';
import { ButtonBase } from '@mui/material';

export const Container = styled(ButtonBase)`
  width: 100%;
  height: 100px;
  box-shadow: 0px 9px 30px 0px rgba(51, 42, 158, 0.05);
  border-radius: 10px;
  padding: 10px 20px;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: -webkit-flex;
    flex-direction: column;
  }

  strong {
    font: 600 15px Rubik;
    margin-bottom: 10px;
  }

  .active {
    color: ${Colors.palette.primary};
    border-bottom: 2px solid ${Colors.palette.primary};
  }

  span {
    display: -webkit-flex;
    align-items: center;
    gap: 8px;
    font: 400 12px Rubik;
    color: #555;
  }

  button {
    border-radius: 100px;
    background: ${Colors.palette.primary};
    border: none;
    outline: 0;
    padding: 10px;
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }
`;
