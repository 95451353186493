export function creditCardType(cc: string): string | undefined {
  const amex = new RegExp('^3[47][0-9]{13}$');

  const hipercard = new RegExp('^606282|^3841(?:[0|4|6]{1})0');

  const elo = new RegExp(
    '^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))'
  );

  const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  const cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
  const cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

  const mastercard = new RegExp('^5[1-5][0-9]{14}$');
  const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

  const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

  const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
  const jcb = new RegExp('^35[0-9]{14}[0-9]*$');

  if (visa.test(cc)) {
    return 'visa';
  }
  if (amex.test(cc)) {
    return 'american_express';
  }
  if (hipercard.test(cc)) {
    return 'Hipercard';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return 'mastercard';
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return 'discover';
  }
  if (elo.test(cc)) {
    return 'elo';
  }
  if (diners.test(cc)) {
    return 'diners_club';
  }
  if (jcb.test(cc)) {
    return 'JCB';
  }
  if (cup1.test(cc) || cup2.test(cc)) {
    return 'CHINA_UNION_PAY';
  }
  return undefined;
}
