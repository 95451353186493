import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { Box } from '@mui/system';
import app from '@services/firebase';
import { CircularProgress } from '@mui/material';
import { getUserPlan } from '@modules/payment/api';
import { getGeoInfo, getUserCountry } from '@modules/main/api';
import Subscription from '@modules/payment/ts/interface/Subscription';

import { User } from '../modules/authentication/ts/session.interface';

interface AuthContextProps {
  user: User | null;
  saveUser: (user: User) => void;
  subscription: Subscription | null | undefined;
  setSubscription: (subscription: Subscription | null) => void;
}

const authContext = createContext({} as AuthContextProps);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [subscription, setSubscription] = useState<
    Subscription | null | undefined
  >(undefined);
  const [user, setUser] = useState<User | null>(() => {
    const user = localStorage.getItem('@user');

    if (user) {
      return JSON.parse(user);
    }

    return null;
  });

  const saveUser = useCallback((user: User) => {
    localStorage.setItem('@user', JSON.stringify(user));
    setUser(user);
  }, []);

  useEffect(() => {
    (async () => {
      if (user) {
        const data = await getUserPlan();
        setSubscription(data);
        const { latitude, longitude, country_code } =
          await getGeoInfo();

        const onlineUserRef = app
          .database()
          .ref(`/users/online/${user.id}`);

        const country = await getUserCountry(country_code);

        onlineUserRef.set({
          id: user.id,
          latitude,
          longitude,
          alpha2Code: country?.codigo,
          subregion: country?.regiao,
          country: country?.nome,
          flag: country?.sinalizador,
          region: country?.regiao
        });
      }
    })();

    return () => {
      const onlineUserRef = app
        .database()
        .ref(`/users/online/${user?.id}`);
      onlineUserRef.remove();
    };
  }, [user]);

  if (user && subscription === undefined) {
    return (
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <authContext.Provider
      value={{ user, saveUser, subscription, setSubscription }}
    >
      {children}
    </authContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(authContext);

  return context;
};

export { useUser, authContext, AuthProvider };
