import Colors from '@constants/Colors';
import {
  heightPercentageToDP,
  widthPercentageToDP
} from '@helpers/responsiveDimensions';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10000000000 !important;
  background: ${Colors.palette.background.primary};
  box-shadow: 0px 9px 30px 0px rgba(0, 0, 0, 0.5);
  border-radius: 15px;

  width: 300px;
  min-height: 300px;

  background: linear-gradient(
    180deg,
    rgba(228, 154, 175, 1) 1.56%,
    rgba(121, 116, 217, 1) 95.1%
  );

  @media (max-width: 599px) {
    bottom: 0;
    right: 0;
    border-radius: 0;
    width: ${widthPercentageToDP(100)}px;
    height: ${heightPercentageToDP(100)}px;
  }

  @media (min-width: 599px) {
    display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  :active {
    cursor: grab;
  }
`;

export const PlayerContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 0px;

  .close-wrapper {
    padding: 0 5px;
    width: 100%;
    display: -webkit-flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  @media (max-width: 599px) {
    .close-wrapper {
      padding: 10px 0 0 10px;
    }
  }

  @media (min-width: 1024px) {
    &:hover {
      .close-wrapper {
        opacity: 1;
      }
    }

    .close-wrapper {
      opacity: 0;
      transition: 0.5s ease;
    }
  }
`;

export const Title = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  line-height: auto;
  letter-spacing: 0.02em;
  color: #ffffff;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  padding: 20px;
  div {
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    label {
      font-family: Rubik;
      font-style: normal;
      font-weight: 600;
      font-size: 1em;
      line-height: auto;
      color: #ffffff;
    }
  }
`;

export const VolumeWrapper = styled.div`
  width: 100%;
  height: max-content;

  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .volume-slider {
    height: 50%;
  }

  div.action {
    width: 100%;
    position: relative;
    /* top: -160px; */
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 599px) {
    display: none;
  }
`;

export const ActionWrapper = styled.div`
  width: 100%;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    display: -webkit-flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-around;
  }
`;
