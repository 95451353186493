import styled from 'styled-components';
import Colors from '@constants/Colors';

export const Container = styled.div`
  width: 100%;
  box-shadow: 0px 9px 30px 0px rgba(51, 42, 158, 0.15);
  border-radius: 10px;
  padding: 30px 20px;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  div {
    display: -webkit-flex;
    flex-direction: column;
  }

  strong {
    font: 600 18px Rubik;
    margin-bottom: 10px;
  }

  p {
    font: 400 12px Rubik;
    color: #555;
  }

  button {
    border-radius: 100px;
    background: ${Colors.palette.primary};
    border: none;
    outline: 0;
    padding: 10px;
    display: grid;
    place-items: center;
    color: white;
    cursor: pointer;
  }
`;
