import styled from 'styled-components';
import { ButtonBase } from '@mui/material';

export const Box = styled(ButtonBase)<{ selected?: boolean }>`
  border: solid 1.5px rgba(59, 76, 104, 0.9);
  box-sizing: border-box;
  border-radius: 14px;
  background-color: ${({ selected }) =>
    selected ? 'rgba(59, 76, 104, 0.9)' : 'transparent'};
  div {
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 150px;
    height: 150px;
    label {
      color: ${({ selected }) => (selected ? '#FFF' : '#3b4c68')};
    }
  }
`;

export const Name = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5em;
  line-height: auto;
  text-align: center;
  color: #3b4c68;
`;

export const Amount = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 2em;
  line-height: auto;
  color: #3b4c68;
`;

export const PriceRule = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
  line-height: auto;
  text-align: center;
  color: #3b4c68;
`;
