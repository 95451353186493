import styled from 'styled-components';
import Colors from '@constants/Colors';

export const Container = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  width: 1200px;

  h1 {
    font-family: Rubik;
    padding: 15px 0;
  }

  @media (max-width: 599px) {
    margin-top: 0;
    padding: 0 20px;
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
    margin-top: 0;
  }
`;

export const ContentInfo = styled.div`
  margin-top: 30px;
  width: 90%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 30px;

  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 3fr;
  }
`;

export const Description = styled.div`
  h3 {
    font: 700 28px Rubik;
    color: ${Colors.palette.typography.common};
    padding: 10px 0;
  }

  p {
    font: 400 18px Open Sans;
    color: #555;
  }
`;

export const ContentList = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  max-height: 350px;
  overflow-y: scroll;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.palette.primary};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    filter: brightness(0);
  }
`;

export const PlayButton = styled.button`
  border-radius: 100px;
  background: ${Colors.palette.primary};
  border: none;
  outline: 0;
  padding: 10px;
  display: grid;
  place-items: center;
  color: white;
  cursor: pointer;
  margin: 25% 0;
`;
