import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Course } from '@modules/courses/ts/course.interface';
import PaymentModal from '@modules/courses/components/PaymentModal';
import { Drawer, Footer, ScreenContainer } from '@shared/components';
import CourseListItem from '@modules/courses/components/CourseListItem';
import useCourse from '@modules/courses/hooks/useCourse';
import { CourseList } from './styles';

const ProfessionalSuccess = () => {
  const { getAcquiredCourses, getProfessionalsSuccess } = useCourse();
  const [esps, setEsps] = useState<Course[] | undefined>();
  const [course, setCourse] = useState<Course | null>(null);

  const [acquiredCourses, setAcquiredCourses] = useState<
    Course[] | undefined
  >();

  const hasAcquired = (itemId: string) =>
    acquiredCourses?.some(({ id }) => id === itemId);

  const handleGetInfo = async () => {
    setAcquiredCourses(await getAcquiredCourses());
    setEsps(await getProfessionalsSuccess());
  };

  useEffect(() => {
    handleGetInfo();
  }, []);

  return (
    <Drawer>
      <ScreenContainer>
        <CourseList>
          <h3>Espiritualidade no Sucesso Profissional</h3>
          {esps?.map((item) => (
            <CourseListItem
              key={item.id}
              id={item.id}
              name={item.name}
              onClick={() => setCourse(item)}
              thumb={item.thumb}
              formats={item.formats}
              acquired={hasAcquired(item.id)}
            />
          ))}
          {esps?.length === 0 && (
            <Box width="100%">Sem cursos disponíveis</Box>
          )}
          {!esps && (
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              padding="30px 0"
            >
              <CircularProgress color="secondary" />
            </Box>
          )}
        </CourseList>
        <PaymentModal
          courseId={course?.id}
          amount={course?.amount}
          description={course?.description}
          title={course?.name}
          open={!!course?.id}
          onClose={() => setCourse(null)}
        />

        <Footer />
      </ScreenContainer>
    </Drawer>
  );
};

export default ProfessionalSuccess;
