import * as React from 'react';
import { ButtonBase } from '@mui/material';
import { Meditations } from '@assets/images';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Category from '@modules/meditations/ts/category.interface';
import { Container, Summary, Details } from './styles';

interface CategoryAccordionProps {
  category: Category;
  onClick: (filter: string) => void;
}

const CategoryAccordion = ({
  category,
  onClick
}: CategoryAccordionProps) => {
  return (
    <Container>
      <Summary
        onClick={() => {
          if (!category.subCategory.length) {
            const filter = `category=${category.name}`;
            onClick(filter);
          }
        }}
        expandIcon={
          category.subCategory.length ? (
            <ExpandMoreIcon fontSize="large" color="secondary" />
          ) : (
            <></>
          )
        }
      >
        <div>
          <img src={Meditations} alt="meditations" />
          <label>{category.name}</label>
        </div>
      </Summary>
      <Details>
        {category.subCategory.map((subCategory) => (
          <ButtonBase
            onClick={() => {
              const filter = `category=${category.name}&subCategory=${subCategory}`;
              onClick(filter);
            }}
            key={subCategory}
          >
            <label>{subCategory}</label>
          </ButtonBase>
        ))}
      </Details>
    </Container>
  );
};
export default CategoryAccordion;
