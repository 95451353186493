import NumberFormat from 'react-number-format';
import { Box, Name, PriceRule, Amount } from './styles';

const PriceCard = ({
  id,
  name,
  setSelected,
  selected,
  period,
  type,
  amount
}: {
  id?: string;
  name?: string;
  setSelected: (selected: string) => void;
  selected?: boolean;
  period?: string;
  type?: 'SUBSCRIPTION' | 'UNIQUE';
  amount: number;
}) => {
  return (
    <Box
      disabled={!id}
      onClick={() => setSelected(id || '')}
      selected={selected}
    >
      <div>
        <Name>{name}</Name>
        <NumberFormat
          value={amount}
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          prefix="R$ "
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          displayType="text"
          renderText={(value) => <Amount>{value}</Amount>}
        />
        {period === 'MONTHLY' && (
          <PriceRule>
            R$ {(amount * 12).toFixed(2).replace('.', ',')}/ano
          </PriceRule>
        )}
        {period === 'SEMESTER' && (
          <PriceRule>
            R$ {(amount / 6).toFixed(2).replace('.', ',')}/mês
          </PriceRule>
        )}
        {period === 'YEARLY' && (
          <PriceRule>
            R$ {(amount / 12).toFixed(2).replace('.', ',')}/mês
          </PriceRule>
        )}
        {!period && <PriceRule>UNICO</PriceRule>}
      </div>
    </Box>
  );
};

export default PriceCard;
