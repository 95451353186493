import axios, { AxiosError, AxiosResponse } from 'axios';
import dotenv from 'dotenv';
import { getToken } from '../../helpers/tokenSession';

dotenv.config();

export const baseURL = process.env.REACT_APP_API_URL as string;

const api = axios.create({
  baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
});

api.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error.response?.data)
);

api.interceptors.response.use(
  (response: AxiosResponse) => Promise.resolve(response.data),
  (error: AxiosError) => Promise.reject(error.response?.data)
);

export default api;
