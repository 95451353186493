import styled from 'styled-components';
import { WhiteLogo } from '@assets/images';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';

const PageTitle = ({ title }: { title: string }) => {
  const history = useHistory();
  return (
    <Actions>
      <IconButton onClick={() => history.goBack()}>
        <HighlightOffIcon fontSize="large" />
      </IconButton>
      <div>
        <Title>{title}</Title>
        <img height="80%" width="80%" src={WhiteLogo} alt="logo" />
      </div>
      <IconButton onClick={() => history.goBack()} />
    </Actions>
  );
};

export const Title = styled.h1`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  margin: 5px 0 20px 0;
  text-align: center;
  color: #3b4c68;
  font-size: 1.5em;
  line-height: auto;
`;

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  max-width: 450px;
  padding: 0 20px 20px 10px;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;

  @media (min-width: 599px) {
    justify-content: center;
  }

  label:first-of-type {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 1.7em;
    line-height: auto;
    text-align: center;

    color: #3b4c68;
  }

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5em;
    line-height: auto;

    color: #3b4c68;
  }
`;

const Actions = styled.div`
  width: 100%;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  button:first-of-type {
    svg {
      color: #3b4c68;
    }
  }
`;

export default PageTitle;
