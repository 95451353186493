import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 30%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 20px 15px;

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 799px) {
    width: 100%;
  }
`;

export const ChatInput = styled.div`
  width: 100%;
  padding-right: 15px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  color: white;

  input {
    width: 80%;
    background: none;
    border: none;
    outline: 0;
    font: 400 16px Rubik;
    color: white;
    padding: 15px 20px;

    ::-webkit-input-placeholder {
      color: #fff;
    }
  }

  button {
    svg {
      color: #fff;
    }
  }
`;

export const MessagesList = styled.div`
  width: 100%;
  max-height: 300px;
  padding: 10px;
  overflow-y: scroll;
  margin-bottom: 15px;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-height: 499px) {
    max-height: 150px;
  }
`;
