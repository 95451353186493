import { useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import moment from 'moment';

import uploadImage from '../../../helpers/uploadImage';
import isAxiosError from '../../../helpers/isAxiosError';

import { signUp } from '../api';
import { CreateUser } from '../ts/session.interface';

const useSignUp = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const createUser = async (data: CreateUser) => {
    try {
      setLoading(true);
      const fileUrl = data.avatar
        ? await uploadImage(data.avatar as File)
        : 'https://isaojose.com.br/wp-content/uploads/2020/12/blank-profile-picture-mystery-man-avatar-973460.jpg';

      await signUp({
        ...data,
        avatar: fileUrl,
        birthdate: moment(data.birthdate, 'DD-MM-YYYY').toDate()
      });

      toast.success('Usuário registrado');
      history.push('/login');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return { createUser, loading };
};

export default useSignUp;
