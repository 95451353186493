import { toast } from 'react-toastify';
import isAxiosError from '@helpers/isAxiosError';
import { addReminder, getSchedules, removeReminder } from '../api';

const useSchedule = () => {
  const getScheduleReminders = async () => {
    try {
      const reminders = await getSchedules();
      return reminders.calendar;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const addEventReminder = async (payload: {
    title: string;
    start: string;
    end: string;
    provider_id: string;
    calendar_id: string;
  }) => {
    try {
      const data = await addReminder(payload);

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const removeEventReminder = async (id: string) => {
    try {
      await removeReminder(id);
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return {
    getScheduleReminders,
    addEventReminder,
    removeEventReminder
  };
};

export default useSchedule;
