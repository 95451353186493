import api from '@services/api';
import Profile, { UpdateProfileData } from '../ts/profile.interface';

export const getUser = async (id: string): Promise<Profile> =>
  await api.get(`/user/${id}`);

export const updateProfile = async ({
  name,
  avatar,
  isThirdPartyConnection,
  birthdate,
  email,
  password
}: UpdateProfileData): Promise<Profile> =>
  await api.put('/user', {
    name,
    avatar,
    isThirdPartyConnection,
    birthdate,
    email,
    password
  });
