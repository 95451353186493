import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButton, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useUser } from '@contexts/AuthContext';
import {
  Container,
  Nav,
  Link,
  NavSession,
  Background,
  Message,
  Title,
  TitleSession,
  NavWrapper
} from './styles';
import {
  AlarmIcon,
  ArrowBack,
  BreadMenu,
  HeaderImage
} from '../../../assets/images';
import { useRouter } from '../../../routes';
import { useDrawerContext } from '../Drawer';

const HeaderBar = () => {
  const history = useHistory();
  const location = useLocation();
  const { routeName } = useRouter();
  const { subscription } = useUser();

  const [current, setCurrent] = useState(5);
  const { setProgress, progress } = useDrawerContext();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    links.forEach((link, index) =>
      link.path === location.pathname
        ? setCurrent(index)
        : setCurrent(-1)
    );
  }, []);

  const handleOpen = () => {
    setProgress(progress ? 0 : 1);
  };

  const links = [
    {
      name: 'Início',
      path: '/home'
    },
    {
      name: 'Assinaturas',
      path: subscription ? '/signature' : '/subscription'
    },
    {
      name: 'Compras',
      path: '/purchase'
    },
    {
      name: 'Perfil',
      path: '/profile'
    },
    {
      name: 'Notificações',
      path: '/notifications'
    },
    {
      name: 'Sair',
      path: '/login'
    }
  ];

  return (
    <Container>
      <Background image={HeaderImage}>
        <NavWrapper>
          <NavSession>
            <TitleSession>
              <Title>
                <b>Olá</b>, vamos <i>ser feliz!</i>
              </Title>
            </TitleSession>
            <Nav>
              {links.map(({ name, path }, index) => (
                <Link
                  key={`desktop-link-${index + 0}`}
                  onClick={() => setCurrent(index)}
                  selected={current === index}
                  to={path}
                >
                  {name}
                </Link>
              ))}
            </Nav>
          </NavSession>
          <Message>
            O caminho para sua felicidade está dentro de você.
            Meditações guiadas por Tadashi Kadomoto.
          </Message>
        </NavWrapper>
      </Background>
      {isMobile && (
        <Box
          width="100%"
          pl={2}
          pr={2}
          display="flex"
          textAlign="center"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {routeName !== 'Home' && (
            <IconButton onClick={() => history.goBack()}>
              <img width={11} src={ArrowBack} alt="arrow-back" />
            </IconButton>
          )}
          {routeName === 'Home' ? (
            <Title>
              <b>Olá</b>, vamos <i>ser feliz!</i>
            </Title>
          ) : (
            <Title>{routeName}</Title>
          )}
          <Box display="flex" flexDirection="row">
            <IconButton onClick={() => history.push('notifications')}>
              <img src={AlarmIcon} alt="alarm" />
            </IconButton>
            <IconButton onClick={handleOpen}>
              <img src={BreadMenu} alt="menu" />
            </IconButton>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default HeaderBar;
