import Colors from '@constants/Colors';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 80px;

  h3 {
    font: 700 18px Rubik;
    padding: 15px 0;
  }

  .remove-filter {
    font: 600 13px Rubik;
    color: ${Colors.palette.primary};
    display: -webkit-flex;
    align-items: center;
    cursor: pointer;
  }

  .live-section-label {
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 599px) {
    padding: 0 10px;
  }
`;

export const CategoriesList = styled.div`
  width: 100%;
  padding: 20px;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  overflow: hidden;
  overflow-x: auto;
`;

export const LiveStreamsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  overflow-x: auto;

  h4 {
    width: 100%;
    font: 600 18px Rubik;
    color: #999;
  }
`;

export const LivesListContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 20px;
`;
