import { BookOutlined } from '@mui/icons-material';
import React from 'react';
import { Container } from './styles';

interface MaterialListItemProps {
  title: string;
  isActive: boolean;
  description: string;
  onSelect: () => void;
}

function MaterialListItem({
  title,
  isActive,
  description,
  onSelect
}: MaterialListItemProps) {
  return (
    <Container onClick={onSelect}>
      <BookOutlined />
      <div>
        <strong className={isActive ? 'active' : ''}>{title}</strong>
        <p>{description}</p>
      </div>
    </Container>
  );
}

export default MaterialListItem;
