import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

const CancelDialog = ({
  type,
  open,
  handleCancelSignature,
  handleCancelPress,
  handleClose
}: {
  type: 'INDEPENDENT' | 'RECURRENT';
  open: boolean;
  handleClose: () => void;
  handleCancelSignature: () => void;
  handleCancelPress: () => void;
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Tem certeza que deseja cancelar esta ${
          type === 'INDEPENDENT' ? 'compra' : 'assinatura'
        }?`}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={() =>
            type === 'INDEPENDENT'
              ? handleCancelPress()
              : handleCancelSignature()
          }
          autoFocus
        >
          SIM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;
