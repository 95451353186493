import React from 'react';
import { Redirect } from 'react-router';
import moment from 'moment';
import Policy from '@shared/components/Policy';
import { CheckBoxOutlined } from '@mui/icons-material';
import PageTitle from '@modules/payment/components/PageTitle';
import { BackgroundImage, PriceCard } from '@shared/components';
import { useUser } from '@contexts/AuthContext';

import { Info, Container } from './styles';

const Signature = () => {
  const { subscription } = useUser();

  if (!subscription) {
    return <Redirect to="/subscription" />;
  }

  return (
    subscription && (
      <BackgroundImage>
        <Container>
          <PageTitle title="Minha Assinatura" />
          <h2>Assinatura {subscription.plan.title}</h2>
          <p>{subscription.plan.description}</p>
          <div className="permissions">
            <h2>Acessos</h2>
            {subscription.plan.permissions.map((item) => (
              <div key={item.id}>
                <CheckBoxOutlined />
                <Info>{item.name}</Info>
              </div>
            ))}
          </div>

          <PriceCard
            id={subscription.plan.id}
            setSelected={() => 0}
            name={subscription.plan.title}
            period={subscription.plan.period}
            amount={subscription.plan.amount}
            type="SUBSCRIPTION"
          />

          <div>
            <Info>
              {moment(subscription.startAt, 'YYYY-MM-DD').format(
                'DD.MM.YYYY'
              )}
            </Info>
            <Info>Até</Info>
            <Info>
              {moment(subscription.endAt, 'YYYY-MM-DD').format(
                'DD.MM.YYYY'
              )}
            </Info>
          </div>
          <Policy />
        </Container>
      </BackgroundImage>
    )
  );
};

export default Signature;
