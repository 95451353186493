import api from '../../services/api';

const useTrackUserEvents = () => {
  const onlineEvent = () => {
    api.post('/user/track-event', {
      lastEvent: 'ONLINE',
      occurredAt: new Date()
    });
  };

  const purchaseCourseEvent = () => {
    api.post('/user/track-event', {
      lastEvent: 'PURCHASE_COURSE',
      occurredAt: new Date()
    });
  };

  const liveEvent = () => {
    api.post('/user/track-event', {
      lastEvent: 'ON_LIVE',
      occurredAt: new Date()
    });
  };

  const playlistEvent = () => {
    api.post('/user/track-event', {
      lastEvent: 'ON_PLAYLIST',
      occurredAt: new Date()
    });
  };

  const meditationEvent = () => {
    api.post('/user/track-event', {
      lastEvent: 'ON_MEDITATION',
      occurredAt: new Date()
    });
  };

  return {
    liveEvent,
    onlineEvent,
    playlistEvent,
    meditationEvent,
    purchaseCourseEvent
  };
};

export default useTrackUserEvents;
