import api from '@services/api';
import {
  Course,
  Content as CourseContent
} from '../ts/course.interface';

export const getAllCourses = async (): Promise<{ data: Course[] }> =>
  await api.get('/course/all?type=CURSO');

export const getAllAssistence = async (): Promise<{
  data: Course[];
}> => await api.get('/course/all?type=MENTORIA');

export const getAllProfessionalCourses = async (): Promise<{
  data: Course[];
}> => await api.get('/course/all?type=PROFISSIONAL');

export const getUserCourses = async (): Promise<Course[]> =>
  await api.get('/user/courses');

export const getCourse = async (courseId: string): Promise<Course> =>
  await api.get(`/course/${courseId}`);

export const getCourseContent = async (
  courseId: string,
  format: string
): Promise<CourseContent[]> =>
  await api.get(
    `/course/content?course_id=${courseId}&format=${format}`
  );

export const acquireCourse = async (courseId: string) =>
  await api.post('/course/obtain', { courseId });
