import React from 'react';

import { Content as CourseContent } from '@modules/courses/ts/course.interface';
import Meditation from '@modules/meditations/ts/meditation.interface';
import millisToMinutesAndSeconds from '@helpers/millisToSeconds';
import {
  MicOutlined,
  PlayArrowSharp,
  VideocamOutlined
} from '@mui/icons-material';
import { Container } from './styles';

interface ItemProps {
  content: CourseContent | Meditation;
  activeContent: CourseContent | Meditation;
  onSelect: () => void;
}

function ContentListItem({
  content,
  activeContent,
  onSelect
}: ItemProps) {
  return (
    <Container onClick={onSelect}>
      <div>
        <strong className={activeContent === content ? 'active' : ''}>
          {content.title}
        </strong>
        <span>
          {content.format === 'AUDIO' && <MicOutlined />}
          {content.format === 'VIDEO' && <VideocamOutlined />}

          {millisToMinutesAndSeconds(content.duration_millis)}
        </span>
      </div>

      <button onClick={onSelect}>
        <PlayArrowSharp />
      </button>
    </Container>
  );
}

export default ContentListItem;
