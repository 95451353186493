import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import {
  Avatar,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material';
import {
  CameraAltSharp,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import Button from '@shared/components/Button';
import TextInput from '@shared/components/Input';

import {
  Container,
  FormContainer,
  ProfilePicContainer
} from './styles';

import useSignUp from '../../hooks/useSignUp';
import signupSchema from '../../validation/signupSchema';

// import Cloud from '@assets/images/clouds.png';
// import Logo from '@assets/images/name_logo.svg';
import { CreateUser } from '../../ts/session.interface';

function Register() {
  const { createUser } = useSignUp();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSelectImage = () => {
    fileInputRef.current?.click();
  };

  const handleRegister = async (values: CreateUser) => {
    await createUser(values);
  };

  return (
    <Container>
      {/* <img src={Cloud} alt="cloud" className="clouds" />

      <img src={Logo} alt="MeditaAqui" className="logo" /> */}

      <h3>Criar Conta</h3>

      <FormContainer>
        <Formik
          initialValues={{
            name: '',
            email: '',
            birthdate: '',
            password: '',
            confirmPassword: '',
            avatar: undefined,
            showPassword: false,
            isThirdPartyConnection: false
          }}
          validationSchema={signupSchema}
          onSubmit={({
            name,
            email,
            birthdate,
            password,
            avatar,
            isThirdPartyConnection
          }) =>
            handleRegister({
              name,
              email,
              birthdate,
              password,
              avatar,
              isThirdPartyConnection
            })
          }
        >
          {({
            values,
            handleChange,
            setFieldValue,
            errors,
            touched
          }) => (
            <Form>
              <Grid container spacing={4}>
                <Grid item xs={12} justifyContent="center">
                  <ProfilePicContainer>
                    <Avatar
                      onClick={handleSelectImage}
                      src={
                        values.avatar &&
                        URL.createObjectURL(values.avatar)
                      }
                    />
                    <input
                      hidden
                      type="file"
                      name="avatar"
                      ref={fileInputRef}
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          setFieldValue(
                            'avatar',
                            e.target?.files?.[0]
                          );
                        }
                      }}
                    />
                    <CameraAltSharp className="edit-icon" />
                  </ProfilePicContainer>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextInput
                      label="Nome"
                      name="name"
                      variant="standard"
                      value={values.name}
                      onChange={handleChange}
                      helperText={touched.name && errors.name}
                      error={touched.name && !!errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextInput
                      label="Data de nascimento"
                      name="birthdate"
                      variant="standard"
                      value={values.birthdate}
                      onChange={handleChange}
                      helperText={
                        touched.birthdate && errors.birthdate
                      }
                      error={touched.birthdate && !!errors.birthdate}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextInput
                      label="Email"
                      name="email"
                      type="email"
                      variant="standard"
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={touched.email && !!errors.email}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      error={touched.password && !!errors.password}
                    >
                      Senha
                    </InputLabel>
                    <Input
                      type={values.showPassword ? 'text' : 'password'}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && !!errors.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setFieldValue(
                                'showPassword',
                                !values.showPassword
                              )
                            }
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText
                      hidden={!touched.password}
                      error={touched.password && !!errors.password}
                    >
                      {errors.password}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextInput
                      label="Confirmar Senha"
                      type={values.showPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      variant="standard"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      helperText={
                        touched.confirmPassword &&
                        errors.confirmPassword
                      }
                      error={
                        touched.confirmPassword &&
                        !!errors.confirmPassword
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <br />

              <Grid item className="center" xs={12}>
                <Button type="submit">Criar conta</Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
}

export default Register;
