import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import useQuery from '@helpers/getQueryParams';
import { PlayArrowSharp } from '@mui/icons-material';
import usePlaylist from '@modules/playlist/hooks/usePlaylist';
import Footer from '@shared/components/Footer';
import ScreenContainer from '@shared/components/ScreenContainer';
import Drawer from '@shared/components/Drawer';
import Meditation from '@modules/meditations/ts/meditation.interface';
import { Content as CourseContent } from '@modules/courses/ts/course.interface';

import useContent from '../../hooks/useContent';
import ContentListItem from '../../components/ContentListItem';

import {
  Container,
  ContentContainer,
  Description,
  ContentList,
  ContentInfo,
  PlayButton
} from './styles';

function MediaPlayer() {
  const { getCourseContent, getMeditations } = useContent();
  const { setNowPlaying, setPlaylist } = usePlaylist();
  const { type } = useParams() as { type: string };
  const query = useQuery();
  const [content, setContent] = useState<
    CourseContent[] | Meditation[] | undefined
  >();
  const [currentContent, setCurrentContent] = useState<
    CourseContent | Meditation | undefined
  >();

  const handleGetCourseContent = async () => {
    const id = query.get('id');
    const format = query.get('format');
    if (id && format) {
      const data = await getCourseContent(id, format);
      setContent(data);
      setCurrentContent(data?.[0]);
      return;
    }

    toast.error('Curso não selecionado corretamente');
  };

  const handleGetMeditationContent = async () => {
    const category = query.get('category');
    const subCategory = query.get('subCategory');
    const format = query.get('format');

    if (category && subCategory && format) {
      const data = await getMeditations(
        category,
        subCategory,
        format
      );
      setContent(data);
      setCurrentContent(data?.[0]);
      return;
    }

    toast.error('Meditação não selecionada corretamente');
  };

  useEffect(() => {
    if (type === 'course') {
      handleGetCourseContent();
    }

    if (type === 'meditation') {
      handleGetMeditationContent();
    }
  }, [type]);

  return (
    <Drawer>
      <Container>
        <ScreenContainer>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ContentContainer>
              {currentContent?.format === 'VIDEO' && (
                <ReactPlayer
                  controls
                  config={{
                    file: {
                      attributes: { controlsList: 'nodownload' }
                    }
                  }}
                  url={currentContent?.url}
                  light={currentContent?.thumb}
                  stopOnUnmount
                  width="90%"
                  height="90%"
                  playIcon={
                    <PlayButton>
                      <PlayArrowSharp />
                    </PlayButton>
                  }
                />
              )}
              <ContentInfo>
                <Description>
                  <h3>{currentContent?.title}</h3>
                  <p>{currentContent?.description}</p>
                </Description>

                <ContentList>
                  {currentContent &&
                    content?.map((item) => (
                      <ContentListItem
                        content={item}
                        activeContent={currentContent}
                        onSelect={() => {
                          if (item.format === 'AUDIO') {
                            setPlaylist(content);
                            setNowPlaying(item, 'SONG');
                          }
                          setCurrentContent(item);
                        }}
                      />
                    ))}
                </ContentList>
              </ContentInfo>
            </ContentContainer>
          </Box>
          <Footer />
        </ScreenContainer>
      </Container>
    </Drawer>
  );
}

export default MediaPlayer;
