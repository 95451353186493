import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLocalStorage from '@helpers/useLocalStorage';

const useLockedMeditations = () => {
  const { infos } = useParams() as { infos: string };

  const unlockedStorage = useLocalStorage('@unlocked');
  const [unlocked, setUnlocked] = useState<string[]>(['Bem-vindos']);

  useEffect(() => {
    const data = unlockedStorage.getItem();
    if (data) {
      const unlockeds = JSON.parse(data) as string[];
      setUnlocked(unlockeds);
    } else {
      unlockedStorage.setItem(JSON.stringify(['Bem-vindos']));
      setUnlocked(['Bem-vindos']);
    }
  }, []);

  const unlockMeditation = (medName: string) => {
    const prevState = [...new Set(unlocked), medName];
    setUnlocked(prevState);
    unlockedStorage.setItem(JSON.stringify(prevState));
  };

  const hasUnlocked = (medName: string) => {
    const hasIntroduction = infos
      .split('=')
      .includes('Introdução à Meditação');
    return hasIntroduction
      ? unlocked.some((name) => name === medName)
      : true;
  };
  return {
    unlocked,
    hasUnlocked,
    setUnlocked,
    unlockedStorage,
    unlockMeditation
  };
};

export default useLockedMeditations;
