import * as yup from 'yup';

const signupSchema = yup.object({
  name: yup.string().required('Insira um nome'),
  email: yup
    .string()
    .email('Insira um email válido')
    .required('Insira um email'),
  avatar: yup.mixed().notRequired(),
  birthdate: yup.string().required('Insira a data de nascimento'),
  password: yup.string().required('Insira uma senha'),
  confirmPassword: yup
    .string()
    .required('O campo de Confirmação de Senha é obrigatório!')
    .oneOf(
      [yup.ref('password'), null],
      'As senhas precisam ser idênticas!'
    ),
  isThirdPartyConnection: yup.boolean()
});

export default signupSchema;
