import api from '@services/api';
import Meditation from '../ts/meditation.interface';
import Category from '../ts/category.interface';

export const getAllCategories = async (): Promise<Category[]> =>
  await api.get('/course/meditation/categories');

export const getAllMeditations = async (
  filter: string
): Promise<{ data: Meditation[] }> =>
  await api.get(`/meditation/filter?${filter}`);

export const updateListeners = async (medId: string) =>
  await api.patch(`/meditation/listeners/${medId}`);
