import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  header {
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
  }

  iframe {
    height: 70vh;
    width: 80%;

    @media (max-width: 699px) {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 699px) {
    width: 100%;
    height: 100%;
  }
`;
