import ReactPlayer from 'react-player';
import { useEffect, useRef, useState } from 'react';
import { usePlayer } from '@contexts/MusicPlayerContent';

const useTrackPlayer = () => {
  const { playlist, currentSong, setNowPlaying } = usePlayer();
  const [isPlaying, setPlayingState] = useState<boolean | undefined>(
    false
  );
  const [track, setTrack] = useState({
    playing: true,
    volume: 1,
    isBuffingLoaded: false,
    progress: 0
  });

  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    play();
  }, [currentSong?.id]);

  const skipToNext = () => {
    const currentIndex = playlist.findIndex(
      (song) => song.id === currentSong?.id
    );
    if (currentIndex < playlist.length - 1) {
      setNowPlaying(playlist[currentIndex + 1], 'SONG');
    }
    setTrack({ ...track, progress: 0 });
  };

  const skipToPrevius = () => {
    const currentIndex = playlist.findIndex(
      (song) => song.id === currentSong?.id
    );
    if (currentIndex > 0) {
      setNowPlaying(playlist[currentIndex - 1], 'SONG');
    }

    setTrack({ ...track, progress: 0 });
  };

  const play = () => {
    setTrack({ ...track, playing: true });
    setPlayingState(true);
  };

  const pause = () => {
    setTrack({ ...track, playing: false });
    setPlayingState(false);
  };

  const togglePlaying = () => {
    const { playing } = track;
    return playing ? pause() : play();
  };

  const handleVolume = (
    action: 'plus' | 'less',
    value?: number | number[]
  ) => {
    const volume = Array.isArray(value) ? value[0] : value;

    if (volume) setTrack({ ...track, volume });
    switch (action) {
      case 'less':
        if (track.volume > 0)
          setTrack({
            ...track,
            volume: Number((track.volume - 0.1).toFixed(1))
          });
        break;

      case 'plus':
        if (track.volume < 1)
          setTrack({
            ...track,
            volume: Number((track.volume + 0.1).toFixed(1))
          });
        break;

      default:
        setTrack({ ...track, volume: 0.5 });
        break;
    }
  };

  const handleTime = (time: number | number[]) => {
    const progress: number = Array.isArray(time) ? time[0] : time;
    playerRef.current?.seekTo(progress / 1000);
    setTrack({ ...track, progress });
  };

  return {
    track,
    isPlaying,
    playerRef,
    skipToNext,
    setTrack,
    play,
    pause,
    handleTime,
    handleVolume,
    skipToPrevius,
    togglePlaying
  };
};

export default useTrackPlayer;
