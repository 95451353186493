import * as yup from 'yup';

const addressSchema = yup.object({
  country: yup.string().required('Insira sua senha'),
  district: yup
    .string()
    .required('Insira o nome do Titular do cartão'),
  uf: yup.string().required('Digite o numero do cartão'),
  street: yup
    .string()
    .required('Digite o código de segurança do cartão'),
  city: yup.string().required('Digite a data de validade do cartão'),
  zipcode: yup.string().required('Selecione')
});

export default addressSchema;
