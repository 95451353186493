import axios from 'axios';
import Country from '../ts/country.inteface';
import GeoInfo from '../ts/geoInfo.interface';

export const getUserCountry = async (
  isoCountryCode: string
): Promise<Country> =>
  await axios
    .get(
      `https://medita-aqui-api-dev-7jhp74c2mq-ue.a.run.app/api/v1/user/country/${isoCountryCode}`
    )
    .then(({ data }: { data: Country }) => data);

export const getGeoInfo = async (): Promise<GeoInfo> => {
  return new Promise((resolve, reject) => {
    axios
      .get('https://ipapi.co/json/')
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
