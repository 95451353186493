import styled from 'styled-components';
import { ButtonBase } from '@mui/material';
import { MediaPlay, MediaUser } from '@assets/images';
import { LockOutlined } from '@mui/icons-material';

const MediaTrack = ({
  title,
  duration,
  listened,
  onClick,
  isLocked = false
}: {
  title: string;
  isLocked?: boolean;
  duration: string;
  listened: number;
  onClick: () => void;
}) => {
  return (
    <Wrapper disabled={isLocked} onClick={onClick}>
      <div>
        <img src={MediaPlay} alt="media-play" />
        {isLocked && <LockOutlined />}
        <Title>{title}</Title>
        <Infos>
          <label>{duration}</label>
          <label>
            <img src={MediaUser} alt="media-user" />
            {listened}X
          </label>
        </Infos>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(ButtonBase)`
  div:first-child {
    width: 100vw;
    max-width: 600px;
    background: rgba(228, 154, 175, 0.2);
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin: 7px 0;
    svg {
      color: #666666;
    }
  }
  @media (min-width: 599px) {
    margin: 7px;
  }
`;

const Title = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9em;
  width: 100%;
  margin: 0 10px;
  line-height: auto;
  letter-spacing: 1px;
  color: rgba(30, 32, 34, 0.6);
`;

const Infos = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  label {
    margin: 0 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 0.7em;
    text-align: center;
    line-height: auto;
    letter-spacing: 1px;
    color: rgba(30, 32, 34, 0.6);
  }
`;

export default MediaTrack;
