import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import isAxiosError from '@helpers/isAxiosError';

import { findAddress, getUserAddress, saveUserAddress } from '../api';
import Address from '../ts/interface/Address';

const useAddress = () => {
  const history = useHistory();
  const { productId, type } = useParams() as {
    productId: string;
    type: 'COURSE' | 'SUBSCRIPTION';
  };

  const [initialStates, setState] = useState({
    country: '',
    district: '',
    uf: '',
    street: '',
    city: '',
    zipcode: ''
  });

  const handleCEP = async (zipcode: string) => {
    if (zipcode.length < 8) return;
    const {
      data: { bairro, uf, logradouro, localidade }
    } = await findAddress(zipcode.replace('-', ''));

    const prevStates = {
      zipcode,
      country: 'Brasil',
      district: bairro,
      uf,
      street: logradouro,
      city: localidade
    };

    setState(prevStates);
  };

  const onSubmit = async (address: Partial<Address>) => {
    try {
      await saveUserAddress(address);
      history.push(`/payment/${productId}/${type}`);
    } catch (error) {
      if (isAxiosError(error)) {
        const { message } = error;
        toast.info(message);
      }
    } finally {
      history.push(`/payment/${productId}/${type}`);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getUserAddress();
        if (Object.keys(response).length) {
          const { country, district, uf, street, city, zipcode } =
            response;
          setState({ country, district, uf, street, city, zipcode });
        }
      } catch (error) {
        if (isAxiosError(error)) {
          const { message } = error;
          toast.error(message);
        }
      }
    })();
  }, []);

  return {
    initialStates,
    handleCEP,
    onSubmit
  };
};

export default useAddress;
