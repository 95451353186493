import React from 'react';
import styled from 'styled-components';

import { TextField, TextFieldProps } from '@mui/material';
import Colors from '../../../constants/Colors';

function TextInput({ ...rest }: TextFieldProps) {
  return <StyledTextField variant="standard" {...rest} />;
}

export default TextInput;

const StyledTextField = styled(TextField)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  /* & label.Mui-focused {
    color: ${(p) => (p.error ? 'red' : Colors.palette.dark)};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${(p) =>
    p.error ? 'red' : Colors.palette.dark};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${(p) => (p.error ? 'red' : Colors.palette.dark)};
    }
  }
  input {
    color: ${(p) => (p.error ? 'red' : Colors.palette.dark)};
    font: 500 14px Rubik;
    border-color: ${(p) => (p.error ? 'red' : Colors.palette.dark)};
  } */
`;
