import { useCallback, useEffect, useState } from 'react';
import app from '@services/firebase';

export interface SnapLive {
  id: string;
  title: string;
  thumb: string;
  sessionId: string;
  streamer: { id: string };
  viewers: number;
  created_at: Date;
  update_at: Date;
}

const useLiveListener = () => {
  const [hasLive, setLiveWatcher] = useState<boolean>(false);
  const [streams, setStreams] = useState<SnapLive[]>([]);

  useEffect(
    useCallback(() => {
      const liveRef = app.database().ref('/streams');

      const subscribe = liveRef.child('/').on('value', (snapshot) => {
        const snapArr: SnapLive[] = [];

        setLiveWatcher(!!snapshot.numChildren());
        snapshot.forEach((snap) => {
          snapArr.push(snap.val());
        });

        setStreams(snapArr);
      });

      return () => liveRef.off('value', subscribe);
    }, [])
  );

  return { hasLive, streams };
};

export default useLiveListener;
