import { Formik } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import {
  BackgroundImage,
  InputMask,
  Input,
  Button
} from '@shared/components';
import PageTitle from '@modules/payment/components/PageTitle';
import paymentSchema from '../../validation/paymentSchema';
import usePayment from '../../hooks/usePayment';
import { FormContainer, Options } from '../styles';

const Payment = () => {
  const { onSubmit } = usePayment();
  return (
    <BackgroundImage>
      <Formik
        initialValues={{
          paymentMethodCode: '',
          cpf: '',
          holderName: '',
          cardNumber: '',
          cardCVV: '',
          cardExpiration: '',
          terms: false
        }}
        onSubmit={onSubmit}
        validationSchema={paymentSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          errors,
          touched,
          isSubmitting
        }) => (
          <FormContainer>
            <Grid container spacing={4}>
              <Grid item textAlign="center" mb={6} xs={12}>
                <PageTitle title="Pagamento" />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  error={touched.cardNumber && !!errors.cardNumber}
                  fullWidth
                >
                  <InputLabel id="select-label">
                    Selecione uma forma de pagamento
                  </InputLabel>

                  <Select
                    variant="standard"
                    labelId="select-label"
                    value={values.paymentMethodCode}
                    name="paymentMethodCode"
                    onChange={handleChange}
                    label="Selecione uma forma de pagamento"
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    <MenuItem value="credit_card">
                      Cartão de Crédito
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {!touched.paymentMethodCode &&
                      !!errors.paymentMethodCode}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Input
                    value={values.holderName}
                    name="holderName"
                    onChange={handleChange}
                    label="Titular do cartão"
                    helperText={
                      touched.holderName && errors.holderName
                    }
                    error={touched.holderName && !!errors.holderName}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputMask
                    value={values.cardNumber}
                    name="cardNumber"
                    onChange={handleChange}
                    format="#### #### #### ####"
                    label="Numero do Cartão"
                    helperText={
                      touched.cardNumber && errors.cardNumber
                    }
                    error={touched.cardNumber && !!errors.cardNumber}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputMask
                    value={values.cardExpiration}
                    name="cardExpiration"
                    onChange={handleChange}
                    format="##/##"
                    label="Validade"
                    helperText={
                      touched.cardExpiration && errors.cardExpiration
                    }
                    error={
                      touched.cardExpiration &&
                      !!errors.cardExpiration
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputMask
                    value={values.cardCVV}
                    name="cardCVV"
                    onValueChange={({ value }) =>
                      setFieldValue('cardCVV', value)
                    }
                    format="####"
                    label="Código"
                    helperText={touched.cardCVV && errors.cardCVV}
                    error={touched.cardCVV && !!errors.cardCVV}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputMask
                    value={values.cpf}
                    name="cpf"
                    onChange={handleChange}
                    format="###.###.###-##"
                    label="CPF do Títular"
                    helperText={touched.cpf && errors.cpf}
                    error={touched.cpf && !!errors.cpf}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange}
                        value={values.terms}
                        name="terms"
                      />
                    }
                    label="Aceito os termos e condições de uso"
                  />
                  <FormHelperText
                    hidden={values.terms}
                    error={!errors.terms}
                  >
                    Aceite os termos
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Options>
              <Button type="submit" loading={isSubmitting}>
                Fazer pagamento
              </Button>
            </Options>
          </FormContainer>
        )}
      </Formik>
    </BackgroundImage>
  );
};

export default Payment;
