import { toast } from 'react-toastify';
import api from '../services/api';
import isAxiosError from './isAxiosError';

const uploadAvatar = async (
  file: File
): Promise<string | undefined> => {
  try {
    const data = new FormData();
    data.append('file', file);
    const res: { url: string } = await api.post('/upload-file', data);

    return res.url;
  } catch (error) {
    if (isAxiosError(error)) {
      toast.error(error);
    }
  }
};

export default uploadAvatar;
