import { ComponentType } from 'react';
import { PreloadScriptProps } from 'opentok-react';

// pages
import { Home } from '@modules/main/pages';
import { Login, Register } from '@modules/authentication/pages';
import {
  Payment,
  Address,
  Subscription,
  Signature
} from '@modules/payment/pages';
import { UpdateUser } from '@modules/profile/pages';
import { MaterialList, MediaPlayer } from '@modules/media/pages';
import {
  ListNotifications,
  ViewNotifications
} from '@modules/notifications/pages';

import { Playlist } from '@modules/playlist/pages';
import { Schedule } from '@modules/schedule/pages';
import { LivesList, WatchLive } from '@modules/lives/pages';
import {
  CoursesList,
  ProfessionalSuccess
} from '@modules/courses/pages';
import { OnlineUsers } from '@modules/onlineUsers/pages';
import {
  CategoriesList,
  MeditationsList
} from '@modules/meditations/pages';
import PurchaseHistoric from '@modules/purchaseHistoric/pages/main';

const routes:
  | {
      key: string;
      requiredSubscription?: boolean;
      name: string;
      path: string;
      Component:
        | ComponentType<PreloadScriptProps>
        | (() => JSX.Element);
    }[] = [
  {
    key: 'Login',
    name: 'Login',
    path: '/login',
    Component: Login
  },
  {
    key: 'Signup',
    name: 'Signup',
    path: '/signup',
    Component: Register
  },
  {
    key: 'Profile',
    name: 'Perfil',
    path: '/profile',
    Component: UpdateUser
  },
  {
    key: 'Home',
    name: 'Home',
    path: '/home',
    Component: Home
  },
  {
    key: 'Payment',
    name: 'Payment',
    path: '/payment/:productId/:type',
    Component: Payment
  },
  {
    key: 'Signature',
    name: 'Signature',
    requiredSubscription: true,
    path: '/signature',
    Component: Signature
  },
  {
    key: 'Address',
    name: 'Address',
    path: '/address/:productId/:type',
    Component: Address
  },
  {
    key: 'Subscription',
    name: 'Subscription',
    path: '/subscription',
    Component: Subscription
  },
  {
    key: 'MediaPlayer',
    name: 'MediaPlayer',
    path: '/media/:type',
    Component: MediaPlayer
  },
  {
    key: 'MaterialList',
    name: 'Material',
    path: '/material',
    Component: MaterialList
  },
  {
    key: 'ListNotifications',
    name: 'Notificações',
    path: '/notifications',
    Component: ListNotifications
  },
  {
    key: 'ViewNotifications',
    name: 'Notificações',
    path: '/notification/:notificationId',
    Component: ViewNotifications
  },
  {
    key: 'Schedule',
    name: 'Calendário',
    path: '/schedule',
    Component: Schedule
  },
  {
    key: 'Playlist',
    name: 'Playlist do Tadashi',
    path: '/playlist',
    requiredSubscription: true,
    Component: Playlist
  },
  {
    key: 'online',
    name: 'Pessoas Online',
    path: '/online',
    Component: OnlineUsers
  },
  {
    key: 'Course',
    name: 'Cursos e Mentorias',
    path: '/courses',
    Component: CoursesList
  },
  {
    key: 'Course',
    name: 'Espiritualidade no Sucesso Profissional',
    requiredSubscription: true,
    path: '/esp',
    Component: ProfessionalSuccess
  },
  {
    key: 'Lives',
    name: 'Lives',
    path: '/lives',
    Component: LivesList
  },
  {
    key: 'Live',
    name: 'Assistir Live',
    path: '/live/:sessionId/:token',
    Component: WatchLive
  },
  {
    key: 'CategoriesList',
    name: 'Categorias',
    path: '/categories',
    requiredSubscription: true,
    Component: CategoriesList
  },
  {
    key: 'MeditationsList',
    name: 'Meditações',
    path: '/meditations/:infos',
    requiredSubscription: true,
    Component: MeditationsList
  },
  {
    key: 'PurchaseHistoric',
    name: 'Historico de compras',
    path: '/purchase',
    Component: PurchaseHistoric
  }
];

export default routes;
