import styled from 'styled-components';
import { Tabs } from '@material-ui/core';
import Colors from '@constants/Colors';

export const Container = styled.div`
  padding: 0 80px;

  @media (max-width: 899px) {
    margin-top: 0;
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  width: 100%;

  h3 {
    font: 600 25px Rubik;
    margin-bottom: 40px;
  }
`;

export const TabsContainer = styled(Tabs)`
  .PrivateTabIndicator-colorSecondary-3 {
    background-color: ${Colors.palette.primary};
  }
`;

export const CourseList = styled.div`
  width: 100%;
  padding: 20px;
  display: -webkit-flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 1em;
    line-height: auto;
    text-align: left;
    color: #77808b;
    margin-bottom: 20px;
  }

  @media (max-width: 599px) {
    width: 100%;
  }
`;
