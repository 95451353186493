import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: white;
  padding: 25px 30px;
  border-radius: 10px;
  display: -webkit-flex;
  flex-direction: column;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2);

  h3 {
    font: 700 20px Rubik;
    color: #333;
  }

  span {
    font: 600 14px Rubik;
    color: #999;
  }

  p {
    font: 400 14px Rubik;
    text-align: left;
    padding: 20px 0;
  }

  button {
    @media (max-width: 599px) {
      width: 100%;
    }
  }
`;
