import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { humanize } from '@modules/playlist/pages/Main';
import usePlaylist from '@modules/playlist/hooks/usePlaylist';
import useContentCounter from '@shared/hooks/useContentCounter';
import useTrackUserEvents from '@shared/hooks/useTrackUserEvents';
import Meditation from '@modules/meditations/ts/meditation.interface';
import useLockedMeditations from '@modules/meditations/hooks/useLockedMeditations';
import {
  MediaTrack,
  ScreenContainer,
  VideoPlayer
} from '@shared/components';
import useMeditations from '../../hooks/useMeditations';

const MeditationsList = () => {
  const [title, setTitle] = useState('');
  const [meditation, setMeditation] = useState<Meditation | null>(
    null
  );
  const { setNowPlaying } = usePlaylist();
  const { meditations, infos } = useMeditations();
  const { meditationEvent } = useTrackUserEvents();

  useEffect(() => {
    const arr = infos.split('=');
    const lastOf = arr.length - 1;
    setTitle(arr[lastOf]);
  }, []);

  const { addConsumedContent, getConsumedContent } =
    useContentCounter();
  const { hasUnlocked, unlockMeditation } = useLockedMeditations();

  const handleMeditation = (med: Meditation, index: number) => {
    addConsumedContent(med.id);

    if (index < meditations.length - 1) {
      unlockMeditation(meditations[index + 1].title);
    }

    switch (med.format) {
      case 'VIDEO':
        setMeditation(med);
        meditationEvent();
        break;
      case 'AUDIO':
        setNowPlaying(med, 'MEDITATION');
        meditationEvent();
        break;
      default:
        break;
    }
  };

  return (
    <ScreenContainer>
      <Container>
        <h3>{title}</h3>
        <div>
          {meditations.map((meditation, index) => (
            <MediaTrack
              isLocked={!hasUnlocked(meditation.title)}
              title={meditation.title}
              duration={humanize(meditation.duration_millis)}
              listened={getConsumedContent(meditation.id)}
              onClick={() => handleMeditation(meditation, index)}
            />
          ))}
          <VideoPlayer
            onClose={() => setMeditation(null)}
            open={!!meditation?.id}
            url={meditation?.url}
          />
        </div>
      </Container>
    </ScreenContainer>
  );
};

export default MeditationsList;

export const Container = styled.div`
  display: -webkit-flex;
  width: 80%;
  flex-direction: column;
  flex-wrap: nowrap;
  h3 {
    padding: 0 20px;
    font: 600 18px Rubik;
  }
`;
