import Meditation from '@modules/meditations/ts/meditation.interface';
import api from '../../../services/api';
import { Content } from '../../courses/ts/course.interface';
import Playlist from '../../playlist/ts/playlist.interface';

export const getCourseItems = async (
  id: string,
  format: string
): Promise<Content[]> =>
  await api.get('/course/content', {
    params: {
      course_id: id,
      format
    }
  });

export const getMeditationContent = async (
  category: string,
  subCategory: string,
  format: string
): Promise<{
  data: Meditation[];
}> =>
  await api.get('/meditation/filter', {
    params: {
      category,
      subCategory,
      format
    }
  });

export const getPlaylist = async (): Promise<{ data: Playlist[] }> =>
  await api.get('/playlist');
