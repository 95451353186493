import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { ButtonBase } from '@mui/material';
import { PlayButton } from '@modules/media/pages/main/styles';
import { CloseOutlined, PlayArrowSharp } from '@mui/icons-material';

const VideoPlayer = ({
  open,
  url,
  onClose
}: {
  open: boolean;
  url: string | undefined;
  onClose: () => void;
}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PlayerWrapper>
          <Header>
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Header>
          <Player
            controls
            config={{
              file: { attributes: { controlsList: 'nodownload' } }
            }}
            url={url}
            stopOnUnmount
            width="100%"
            height="100%"
            playIcon={
              <PlayButton>
                <PlayArrowSharp />
              </PlayButton>
            }
          />
        </PlayerWrapper>
      </Modal>
    </>
  );
};

export const PlayerWrapper = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: -webkit-flex;
  z-index: 2;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  top: 0;
`;

export const IconButton = styled(ButtonBase)`
  margin: 20px;
  padding: 10px;
  svg {
    color: white;
  }
`;

export const Player = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
`;

export default VideoPlayer;
