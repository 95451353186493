import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useContext, useState } from 'react';
import useTrackUserEvents from '@shared/hooks/useTrackUserEvents';

import isAxiosError from '../../../helpers/isAxiosError';
import { setToken } from '../../../helpers/tokenSession';

import { signIn } from '../api';
import { authContext } from '../../../contexts/AuthContext';

const useLogin = () => {
  const history = useHistory();
  const { onlineEvent } = useTrackUserEvents();
  const { saveUser } = useContext(authContext);
  const [loading, setLoading] = useState(false);

  const startSession = async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }): Promise<void> => {
    try {
      setLoading(true);
      const userSession = await signIn({ email, password });
      saveUser(userSession.user);
      setToken(userSession);
      onlineEvent();
      if (userSession.user.name === 'apple') {
        toast.warning('Complete seu cadastro para continuar');
        return history.push('/profile');
      }
      history.push('/home');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  /* const endSession = async () => {
    try {
      setUser(null);
      setAuth(false);
    } catch (error) {
      if (isAxiosError(error)) {
        throw showError(error.message, error.status);
      }
    }
  }; */

  return { startSession, loading };
};

export default useLogin;
