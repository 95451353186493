import NumberFormat from 'react-number-format';
import { Input } from './styles';

interface InputProps {
  format?: string;
  label: string;
  helperText?: string | false | undefined;
  error?: boolean;
  name?: string;
  onValueChange?: ({
    formattedValue,
    value
  }: {
    formattedValue: string;
    value: string;
  }) => void;
  value: string | number | null | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const InputMask = ({
  format,
  label,
  value,
  helperText,
  error,
  onChange,
  name,
  onValueChange
}: InputProps) => {
  return (
    <NumberFormat
      onValueChange={onValueChange}
      value={value}
      helperText={helperText}
      error={error}
      displayType="input"
      name={name}
      fullWidth
      onChange={onChange}
      color="primary"
      label={label}
      variant="standard"
      customInput={Input}
      format={format}
    />
  );
};

export default InputMask;
