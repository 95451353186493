/* eslint-disable no-console */
import { Grid } from '@mui/material';
import { useHistory } from 'react-router';
import { useUser } from '@contexts/AuthContext';
import useLiveListener from '@shared/hooks/useLiveListener';
import { Drawer, Footer, ScreenContainer } from '@shared/components';

import {
  CalendarBackground,
  CalendarIcon,
  ESPBackground,
  ESPIcon,
  LearnBackground,
  LearnIcon,
  LiveBackground,
  LiveIcon,
  MeditationBackground,
  MeditationIcon,
  MusicBackground,
  MusicIcon
} from './assets';
import { BottomCard, Card, Comunity, World } from './components';

const Home = () => {
  const history = useHistory();

  const { subscription } = useUser();
  const { hasLive } = useLiveListener();

  const verifyUserAccess = (route: string) => {
    const requiredSubscription = ['categories', 'playlist', 'esp'];
    if (requiredSubscription.includes(route) && !subscription)
      history.push(`/subscription`);
    else history.push(`/${route}`);
  };

  return (
    <Drawer>
      <ScreenContainer>
        <Grid container display="flex">
          <Grid
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            xl={12}
            m={1}
            mb={3}
            spacing={0.5}
          >
            <div className="column-card">
              <Card
                background={MeditationBackground}
                title="Meditações Guiadas"
                color="rgba(142, 150, 255, 0.6)"
                icon={MeditationIcon}
                onClick={() => verifyUserAccess('categories')}
                large
              />

              <Card
                background={LearnBackground}
                title="Cursos e Mentorias"
                color="rgba(255, 141, 0, 0.55)"
                icon={LearnIcon}
                onClick={() => verifyUserAccess('courses')}
              />
            </div>

            <div className="column-card">
              <Card
                onLive={hasLive}
                title="Lives"
                background={LiveBackground}
                color="rgba(246, 210, 80, 0.6)"
                icon={LiveIcon}
                onClick={() => verifyUserAccess('lives')}
              />

              <Card
                large
                background={ESPBackground}
                title="Espiritualidade no Sucesso Profissional"
                color="rgba(32, 179, 216, 0.6)"
                icon={ESPIcon}
                onClick={() => verifyUserAccess('esp')}
              />
            </div>
          </Grid>
          <Grid item mb={3} xs={12} sm={12} md={12} lg={12} xl={12}>
            <Comunity />
          </Grid>
          <Grid item mb={3} xs={12} sm={12} md={12} lg={12} xl={12}>
            <World />
          </Grid>
          <Grid item xs={12}>
            <BottomCard
              radius
              background={MusicBackground}
              title="Playlist do Tadashi"
              color="rgba(255, 153, 119, 0.6)"
              icon={MusicIcon}
              onClick={() => verifyUserAccess('playlist')}
            />
          </Grid>
          <Grid item xs={12}>
            <BottomCard
              background={CalendarBackground}
              title="Agenda do Medita.Aqui"
              color="rgba(32, 133, 216, 0.6)"
              icon={CalendarIcon}
              onClick={() => verifyUserAccess('schedule')}
            />
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </ScreenContainer>
    </Drawer>
  );
};

export default Home;
