import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import { creditCardType } from '@helpers/checkCreditBrand';
import useTrackUserEvents from '@shared/hooks/useTrackUserEvents';
import { subscribePlan, purchaseCourse } from '../api';

interface Signature {
  paymentMethodCode: string;
  cpf: string;
  holderName: string;
  cardNumber: string;
  cardCVV: string;
  cardExpiration: string;
  terms: boolean;
}

const usePayment = () => {
  const { purchaseCourseEvent } = useTrackUserEvents();
  const { productId, type } = useParams() as {
    productId: string;
    type: 'COURSE' | 'SUBSCRIPTION';
  };
  const history = useHistory();

  const onSubmit = async ({
    paymentMethodCode,
    cpf,
    holderName,
    cardNumber,
    cardCVV,
    cardExpiration
  }: Signature) => {
    try {
      const paymentCompanyCode = creditCardType(
        cardNumber?.replace(/ /g, '')
      );

      if (!paymentCompanyCode) {
        toast.info('número de cartão inválido');
        return;
      }

      if (type === 'SUBSCRIPTION') {
        await subscribePlan({
          planId: productId,
          cpf,
          card: {
            cardCVV,
            cardExpiration,
            cardNumber,
            holderName,
            paymentCompanyCode,
            paymentMethodCode
          }
        });
      } else {
        await purchaseCourse({
          courseId: productId,
          cpf,
          card: {
            cardCVV,
            cardExpiration,
            cardNumber,
            holderName,
            paymentCompanyCode,
            paymentMethodCode
          },
          paymentMethod: paymentMethodCode.toUpperCase()
        });
      }
      purchaseCourseEvent();
      toast.success('Compra realizada com sucesso.');
      history.push('/home');
    } catch (error) {
      toast.error('Não foi possivel processar sua compra');
    }
  };

  return {
    onSubmit
  };
};

export default usePayment;
