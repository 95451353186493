import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 80px;

  h3 {
    font: 700 24px Rubik;
    margin-bottom: 20px;
  }

  @media (max-width: 599px) {
    padding: 0 10px;
    margin-top: 0;
  }
`;

export const MaterialsList = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  width: 60%;

  @media (max-width: 599px) {
    width: 100%;
  }
`;
