import styled from 'styled-components';
import { CircularProgress, IconButton } from '@mui/material';

const ActionButton = ({
  icon,
  loading = false,
  onClick
}: {
  onClick?: () => void;
  icon: string;
  loading?: boolean;
}) => {
  return (
    <StyledIconButton onClick={onClick}>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <img src={icon} alt="icon" />
      )}
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton)`
  img {
    height: 40px;
    width: 40px;
  }
`;

export default ActionButton;
