import isAxiosError from '@helpers/isAxiosError';
import { toast } from 'react-toastify';

import {
  getAllCourses,
  getAllAssistence,
  getUserCourses,
  getAllProfessionalCourses,
  acquireCourse
} from '../api';

const useCourse = () => {
  return {
    getCourses: async () => {
      try {
        const courses = await getAllCourses();
        return courses.data;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    },
    getMentorships: async () => {
      try {
        const assistances = await getAllAssistence();
        return assistances.data;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    },
    getAcquiredCourses: async () => {
      try {
        const courses = await getUserCourses();
        return courses;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    },
    getProfessionalsSuccess: async () => {
      try {
        const esps = await getAllProfessionalCourses();
        return esps.data;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    },
    acquireFreeCourse: async (id: string) => {
      try {
        const res = await acquireCourse(id);
        return res;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      }
    }
  };
};

export default useCourse;
