export default {
  palette: {
    background: { primary: '#FFF', secondary: '#F5F9FF' },
    primary: '#FF9977',
    secondary: '#E49AAF',
    dark: 'rgba(3, 1, 76, 1)',
    typography: {
      common: {
        black: '#333',
        white: '#FFF'
      },
      primary: '#2C5E6B'
    }
  }
};
