import moment from 'moment';
import { toast } from 'react-toastify';
import { useUser } from '@contexts/AuthContext';
import { useCallback, useState, useEffect } from 'react';

import isAxiosError from '@helpers/isAxiosError';

import {
  cancelPurchase,
  cancelSubscription,
  getUserPurchases
} from '../api';
import Transaction from '../ts/Transaction';

interface Purchases {
  date: string;
  purchases: Transaction[];
}

const usePurchaseHistoric = () => {
  const [refreshing, setRefresh] = useState(false);
  const [purchases, setPurchases] = useState<Purchases[]>([]);

  const { user, setSubscription } = useUser();

  const fetchState = useCallback(async () => {
    try {
      if (!user) {
        return;
      }
      const { transactions } = await getUserPurchases(user.id);

      const prevDates = transactions.map(
        (item) => item.created_at.toString().split('T')[0]
      );

      const dates = [...new Set(prevDates)];

      const prevState: Purchases[] = dates.map((date) => {
        const purchases = transactions.filter((transaction) => {
          const compare = moment(date, 'YYYY-MM-DD');
          const reference = moment(
            transaction.created_at,
            'YYYY-MM-DD'
          );
          if (reference.isSame(compare)) return transaction;
          return undefined;
        });
        return { date, purchases };
      });

      setPurchases(prevState);
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  }, []);

  useEffect(() => {
    fetchState();
  }, []);

  const onRefresh = async () => {
    try {
      setRefresh(true);
      await fetchState();
    } catch (error) {
      setRefresh(false);
    } finally {
      setRefresh(false);
    }
  };

  const handleCancel = async (courseId: string) => {
    try {
      await cancelPurchase(courseId);
      await onRefresh();
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription();
      setSubscription(null);
      await onRefresh();
      toast.success('Assinatura cancelada com sucesso.');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(
          'Não foi possível cancelar a sua assinatura no momento, tente novamente mais tarde ou entre em contato através do e-mail (contato@meditaaqui.com.br)'
        );
      }
    }
  };

  return {
    purchases,
    onRefresh,
    refreshing,
    handleCancel,
    handleCancelSubscription
  };
};

export default usePurchaseHistoric;
