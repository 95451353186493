import Colors from '@constants/Colors';
import styled from 'styled-components';

export const Image = styled.img``;

export const Box = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100px;
  img {
    position: static;
    margin-top: -2px;
    pointer-events: none;
    width: 100%;
  }
`;

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.palette.background.secondary};
  width: 100%;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  /* @media (max-width: 425px) {
    position: relative;
    top: -3%;
  }

  @media (min-width: 426px) and (max-width: 1024px) {
    position: relative;
    top: -5%;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    position: relative;
    top: -7%;
  }

  @media (min-width: 1441px) {
    position: relative;
    top: -15%;
  } */
`;

export const HeaderSession = styled.div`
  z-index: 1;
  pointer-events: none;
`;
