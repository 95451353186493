import React from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { Content } from '@modules/courses/ts/course.interface';

import { Container } from './styles';

interface PdfReaderProps {
  open: boolean;
  toggleOpen: () => void;
  content: Content | null;
}

function PdfReader({ open, toggleOpen, content }: PdfReaderProps) {
  return (
    <Dialog
      open={open}
      onClose={toggleOpen}
      maxWidth="xl"
      fullScreen={window.screen.width < 699}
      fullWidth
    >
      <Container>
        <header>
          <DialogTitle>{content?.title}</DialogTitle>
          <IconButton onClick={toggleOpen}>
            <CloseOutlined />
          </IconButton>
        </header>
        <iframe
          title={content?.title}
          allowFullScreen
          src={content?.url}
        />
      </Container>
    </Dialog>
  );
}

export default PdfReader;
