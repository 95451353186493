import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Drawer, ScreenContainer } from '@shared/components';
import CategoryAccordion from '../../components/CategoryAccordion';
import useCategories from '../../hooks/useCategories';

const CategoriesList = () => {
  const history = useHistory();
  const { categories } = useCategories();

  const handleCategories = (filter: string) => {
    history.push(`/meditations/${filter}`);
  };

  return (
    <Drawer>
      <ScreenContainer>
        <Container>
          {categories.map((category) => (
            <CategoryAccordion
              onClick={(filter) => handleCategories(filter)}
              key={category.id}
              category={category}
            />
          ))}
          <CategoryAccordion
            onClick={() => 0}
            category={{
              id: 'sdas',
              name: `Para Crianças (em breve)`,
              subCategory: []
            }}
          />
        </Container>
      </ScreenContainer>
    </Drawer>
  );
};

const Container = styled.div`
  display: -webkit-flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
`;

export default CategoriesList;
