import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import styled from 'styled-components';
import { Drawer, Footer } from '@shared/components';
import ScreenContainer from '@shared/components/ScreenContainer';
import CancelDialog from '@modules/purchaseHistoric/components/CancelDialog';

import HistoricCard from '../../components/Card';
import usePurchaseHistoric from '../../hooks/usePurchaseHistoric';

const PurchaseHistoric = () => {
  const { handleCancel, purchases, handleCancelSubscription } =
    usePurchaseHistoric();

  const handleCancelPress = (courseId: string) => {
    handleCancel(courseId);
  };

  const handleCancelSignature = async () => {
    await handleCancelSubscription();
  };

  const [open, setOpen] = React.useState(false);
  const [courseId, setCourseId] = React.useState<string>('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Drawer>
      <ScreenContainer>
        <Container>
          {purchases.length ? (
            purchases
              .sort(({ date: a }, { date: b }) =>
                moment(b).isBefore(a) ? -1 : 1
              )
              .map((item) => {
                return (
                  <HistoricWrapper key="key">
                    <DayWrapper>
                      <label>
                        {moment(item.date, 'YYYY-MM-DD').format('DD')}
                      </label>
                      <label>
                        {moment(item.date, 'YYYY-MM-DD').format(
                          'MMM'
                        )}
                      </label>
                    </DayWrapper>
                    {item.purchases
                      .sort(({ created_at: a }, { created_at: b }) =>
                        moment(b).isBefore(a) ? -1 : 1
                      )
                      .map((item) => (
                        <HistoricCard
                          key={item.id}
                          handleCancelPress={() => {
                            if (item.courseId)
                              setCourseId(item.courseId);
                            handleOpen();
                          }}
                          handleCancelSignature={() => {
                            setCourseId('');
                            handleOpen();
                          }}
                          type={item.type}
                          method={item.method}
                          amount={item.amount}
                          status={item.status}
                          thumb={item.course?.thumb || ''}
                          name={
                            item.course?.name ||
                            item.subscription?.plan.title ||
                            ''
                          }
                          date={item.created_at}
                        />
                      ))}
                  </HistoricWrapper>
                );
              })
          ) : (
            <>
              <Message>
                <label>Você ainda não realizou nenhuma compra</label>
              </Message>
            </>
          )}
        </Container>

        <CancelDialog
          handleClose={handleClose}
          handleCancelPress={() => handleCancelPress(courseId)}
          handleCancelSignature={handleCancelSignature}
          open={open}
          type={courseId ? 'INDEPENDENT' : 'RECURRENT'}
        />

        <Footer />
      </ScreenContainer>
    </Drawer>
  );
};

const Message = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
`;

const Container = styled.div`
  padding: 0 20px;
  width: 100%;
  max-width: 450px;
`;

const HistoricWrapper = styled.div`
  align-items: flex-start;
  width: 100%;
  margin: 10px 0;
`;

const DayWrapper = styled.div`
  label:first-of-type {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4em;
    line-height: auto;
    display: -webkit-flex;
    align-items: center;
    text-align: center;
    color: #3f3d56;
  }
  label:last-of-type {
    font-family: Montserrat;
    font-size: 1.1em;
    font-style: normal;
    font-weight: 500;
    line-height: auto;
    text-transform: capitalize;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export default PurchaseHistoric;
