import {
  createContext,
  ReactNode,
  useContext,
  useState
} from 'react';

import { Song } from '@modules/playlist/ts/playlist.interface';
import Meditation from '@modules/meditations/ts/meditation.interface';
import { Content as CourseContent } from '@modules/courses/ts/course.interface';

interface PlaylistType {
  currentSong: Song | Meditation | CourseContent | null;
  playlist: Song[] | Meditation[] | CourseContent[];
  playerType: 'MEDITATION' | 'SONG' | undefined;
  setPlaylist: (
    playlist: Song[] | Meditation[] | CourseContent[]
  ) => void;
  isPlayerActive: boolean;
  togglePlayer: (state: boolean) => void;
  setNowPlaying: (
    song: Song | Meditation | CourseContent,
    type: 'MEDITATION' | 'SONG' | undefined
  ) => void;
}

const PlayerContext = createContext<PlaylistType>({} as PlaylistType);

const PlayerProvider = ({ children }: { children: ReactNode }) => {
  const [playerType, setType] = useState<
    'MEDITATION' | 'SONG' | undefined
  >(undefined);
  const [isPlayerActive, setIsPlayerActive] = useState(false);
  const [playlist, setPlaylist] = useState<
    Song[] | Meditation[] | CourseContent[]
  >([]);
  const [currentSong, setCurrentSong] = useState<
    Song | Meditation | CourseContent | null
  >(null);

  const togglePlayer = (state: boolean) => {
    setIsPlayerActive(state);
  };

  const setNowPlaying = async (
    song: Song | Meditation | CourseContent,
    type: 'MEDITATION' | 'SONG' | undefined
  ) => {
    setCurrentSong(song);
    togglePlayer(true);
    setType(type);
  };

  return (
    <PlayerContext.Provider
      value={{
        playlist,
        playerType,
        setPlaylist,
        currentSong,
        isPlayerActive,
        togglePlayer,
        setNowPlaying
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

const usePlayer = () => {
  const context = useContext(PlayerContext);

  return context;
};

export { usePlayer, PlayerContext, PlayerProvider };
