import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#03014C'
    },
    secondary: {
      main: '#FF9977'
    },
    common: {
      white: '#FFF'
    }
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#FF9977',
          color: '#FF9977'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:not(:last-child)': {
            borderBottom: 0
          },
          '&:before': {
            display: 'none'
          }
        }
      }
    }
  }
});

export default theme;
