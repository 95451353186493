import { useState } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Policy from '@shared/components/Policy';
import PageTitle from '@modules/payment/components/PageTitle';
import {
  BackgroundImage,
  Button,
  PriceCard
} from '@shared/components';
import { Container, Title, Options, PriceWrapper } from '../styles';
import useSubscription from '../../hooks/useSubscription';

const Subscription = () => {
  const history = useHistory();
  const [selected, setSelected] = useState('');
  const { plans } = useSubscription();

  const onSubmit = () => {
    history.push(`/address/${selected}/SUBSCRIPTION`);
  };

  return (
    <BackgroundImage>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PageTitle title="Assinatura" />
          </Grid>
          <Grid item xs={12}>
            <Title>
              <h1>Aprofunde-se no conhecimento da Meditação</h1>
              <p>
                Como assinante, além das meditações ao vivo, você
                poderá praticar a sua meditação em várias categorias,
                do básico ao avançado, incluindo exercícios para
                controle da ansiedade, relaxamentos para dormir,
                alinhamento energético, exercícios para formação de
                campos de energia e muito mais! Todo esse conteúdo
                guiado por Tadashi Kadomoto através de vídeos, áudios
                e textos explicativos.
              </p>
            </Title>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <PriceWrapper>
              {plans.map(({ id, title, period, amount }) => (
                <PriceCard
                  id={id}
                  setSelected={setSelected}
                  selected={id === selected}
                  name={title}
                  period={period}
                  amount={amount}
                  type="SUBSCRIPTION"
                />
              ))}
            </PriceWrapper>
          </Grid>
        </Grid>
        <Options>
          <Button onClick={onSubmit}>Continuar</Button>
        </Options>
        <Policy />
      </Container>
    </BackgroundImage>
  );
};

export default Subscription;
