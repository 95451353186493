import styled from 'styled-components';

interface BackgroundProps {
  image: string;
}

export const Container = styled.div<{ large: boolean }>`
  max-width: 280px;
  width: 95%;
  height: 45em;
  max-height: 330px;
  margin: 5px;

  @media (max-width: 300px) {
    height: 160px;
    width: 120px;
  }

  @media (min-width: 300px) and (max-width: 717px) {
    height: ${({ large }) => (large ? 210 : 190)}px;
    width: 160px;
  }

  @media (min-width: 717px) and (max-width: 768px) {
    max-height: 250px;
  }

  @media (min-width: 769px) {
    max-height: 300px;
  }
`;

export const Background = styled.div<BackgroundProps>`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
`;

export const Wrapper = styled.div<{ color: string }>`
  height: 100%;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  background-color: ${({ color }) => color};
`;

export const Button = styled.button`
  border: 1px solid #ffffff;
  background: none;
  color: white;
  margin: 5px;
  border-radius: 5px;
  padding: 10px 30px;
  @media (max-width: 300px) {
    padding: 5px 15px;
    display: none;
  }
`;

export const Title = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: auto;
  text-align: center;

  color: #ffffff;
`;

export const Icon = styled.img``;
