import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import {
  MonthView,
  Appointments
} from '@devexpress/dx-react-scheduler-material-ui';
import Colors from '@constants/Colors';

export const Container = styled.div`
  h2 {
    font: 700 25px Rubik;
    color: #777;
    margin-bottom: 40px;
  }

  @media (max-width: 599px) {
    margin-top: 0;
    padding: 0 10px;
  }

  @media (min-width: 758px) {
    padding: 0 20px;
  }
`;

export const EventsList = styled.div`
  padding: 30px 0;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  gap: 15px;

  h1 {
    font: 700 20px Rubik;
    margin-bottom: 15px;
  }

  @media (max-width: 599px) {
    width: 100%;
  }
`;

export const StyledMonthSchedule = styled(Paper)`
  background: white;
  padding: 30px 0;

  width: auto;
  border: none !important;
`;

export const StyledDayScaleComponent = styled(MonthView.DayScaleCell)`
  border: none !important;

  div {
    font: 600 18px Rubik;
    color: #999;
    border: none !important;
    text-transform: capitalize;
    padding: 20px;

    @media (max-width: 599px) {
      padding: 5px 0;
    }
  }
`;

export const StyledScaleRow = styled(MonthView.DayScaleRow)`
  border: none !important;
`;

export const StyledTableComponent = styled(MonthView.TimeTableLayout)`
  border: none;
`;

export const StyledTimeTableCell = styled(MonthView.TimeTableCell)`
  div:first-of-type {
    background: none;
    border-radius: 40px;
    text-decoration: double underline 3px
      ${(p) => (p.hasEvent ? Colors.palette.primary : 'transparent')};
    border: 1px solid
      ${(p) =>
        p.isSelected ? Colors.palette.primary : 'transparent'};

    color: ${(p) => p.today && '#333'};
    border: ${(p) => p.today && '2px solid lightblue !important'};

    ${(p) =>
      p.today &&
      'width: 30px; height: 30px !important; color: #222; padding-bottom: 15px'};
  }
`;

export const StyledAppointmentComponent = styled(
  Appointments.Appointment
)`
  background-color: ${Colors.palette.primary} !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 30px !important;
`;

export const StyledTableRow = styled(MonthView.TimeTableRow)`
  div:first-of-type {
    height: 50px;
    border: none;
    font: 600 14px Rubik;
    padding-bottom: 15px;
  }
`;
