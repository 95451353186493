import { useState, useEffect, useCallback } from 'react';

import app from '@services/firebase';

import { useUser } from '@contexts/AuthContext';

import Notify from '../api/interface/Notify';

const useNotification = () => {
  const [notifications, setNotify] = useState<Notify[]>([]);
  const [unviewed, setUnview] = useState(false);

  const { user } = useUser();

  const fetchData = useCallback(async () => {
    const col = await app
      .firestore()
      .collection('users')
      .doc(user?.id)
      .collection('notifications')
      .orderBy('createdAt', 'desc')
      .get();

    const prevArr: Notify[] = [];
    col.forEach((notify) => {
      const data = { id: notify.id, ...notify.data() } as Notify;

      if (!data.read) {
        setUnview(true);
      }

      prevArr.push(data);
    });
    setNotify(prevArr);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  useEffect(() => {
    const unsub = app
      .firestore()
      .collection('users')
      .doc(user?.id)
      .collection('notifications')
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data = {
            id: change.doc.id,
            ...change.doc.data()
          } as Notify;

          if (change.type === 'added' || change.type === 'modified') {
            fetchData();
            if (!data.read) {
              setUnview(true);
            } else {
              setUnview(false);
            }
          }
        });
      });

    return () => unsub();
  }, []);

  const handleView = (notifyId: string) => {
    const ref = app
      .firestore()
      .collection('users')
      .doc(user?.id)
      .collection('notifications')
      .doc(notifyId);
    ref.update({
      read: true
    });
  };

  return {
    unviewed,
    fetchData,
    handleView,
    notifications
  };
};

export default useNotification;
