import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  padding: 32px;
  h1 {
  }

  p {
  }
`;
