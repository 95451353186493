import { IconButton, Typography } from '@mui/material';
import {
  createContext,
  ReactNode,
  useContext,
  useState
} from 'react';
import { Box } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { useUser } from '@contexts/AuthContext';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { heightPercentageToDP } from '../../../helpers/responsiveDimensions';
import { PageWrapper, Container, MenuWrapper, Link } from './styles';
import {
  HomeIcon,
  LoginIcon,
  NotificationIcon,
  ProfileIcon,
  ShoppingIcon,
  SignatureIcon
} from '../../../assets/images';

interface Drawer {
  progress: number;
  setProgress: (progress: number) => void;
}

export const drawerContext = createContext({} as Drawer);

export const DrawerProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [progress, setProgress] = useState(0);
  const { Provider } = drawerContext;
  return (
    <Provider value={{ progress, setProgress }}>{children}</Provider>
  );
};

export const useDrawerContext = () => {
  const context = useContext(drawerContext);
  return context;
};

const Drawer = ({ children }: { children: ReactNode }) => {
  const { setProgress, progress } = useDrawerContext();
  const history = useHistory();
  const { subscription } = useUser();

  const handleOpen = () => {
    setProgress(progress ? 0 : 1);
  };

  const links = [
    {
      name: 'Início',
      path: '/home',
      icon: HomeIcon
    },
    {
      name: 'Assinaturas',
      path: subscription ? '/signature' : '/subscription',
      icon: SignatureIcon
    },
    {
      name: 'Compras',
      path: '/purchase',
      icon: ShoppingIcon
    },
    {
      name: 'Perfil',
      path: '/profile',
      icon: ProfileIcon
    },
    {
      name: 'Notificações',
      path: '/notifications',
      icon: NotificationIcon
    }
  ];

  return (
    <>
      <Container>
        <MenuWrapper>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
          >
            <Box position="fixed" left={0}>
              <IconButton onClick={handleOpen}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <Typography color="#3b4c68">Menu</Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            height={heightPercentageToDP(60)}
            justifyContent="flex-end"
          >
            <Box
              display="flex"
              flexDirection="column"
              ml={2}
              height={heightPercentageToDP(50)}
              justifyContent="space-around"
            >
              {links.map(({ name, path, icon }, index) => (
                <Box
                  key={`link-${index + 0}`}
                  display="flex"
                  flexDirection="row"
                >
                  <img src={icon} alt={name} />
                  <Link
                    onClick={() => {
                      history.push(path);
                      handleOpen();
                    }}
                  >
                    {name}
                  </Link>
                </Box>
              ))}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              position="absolute"
              bottom={30}
              left={20}
            >
              <img src={LoginIcon} alt="login-icon" />
              <Link
                onClick={() => {
                  history.push('/login');
                }}
              >
                Sair
              </Link>
            </Box>
          </Box>
        </MenuWrapper>
        <PageWrapper progress={progress}>{children}</PageWrapper>
      </Container>
    </>
  );
};

export default Drawer;
