import React from 'react';
import styled from 'styled-components';

import BgDefaultImg from '@assets/images/live-default.png';
import { ButtonBase } from '@mui/material';

interface LiveItemProps {
  url: string;
  title: string;
  views: number;
  onClick: () => void;
}

function LiveItem({ url, title, views, onClick }: LiveItemProps) {
  const background = url === '' || !url ? BgDefaultImg : url;
  return (
    <Container onClick={onClick} background={background}>
      <InfoContainer>
        <h3>{title}</h3>
        <span>{views} visualizações</span>
      </InfoContainer>
    </Container>
  );
}

export default LiveItem;

const Container = styled(ButtonBase)<{ background: string }>`
  width: 100%;
  height: 215px;
  background-image: url(${(p) => p.background});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease;

  :hover {
    transform: scale(1.05);
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 215px;
  border-radius: 10px;
  padding: 15px 10px;
  color: white;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );

  h3 {
    margin: 0;
    padding: 0;
  }

  span {
    font: 400 12px Rubik;
    color: #666;
  }
`;
