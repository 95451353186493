import React from 'react';
import { IconButton } from '@mui/material';
import { SendSharp } from '@material-ui/icons';
import useWatchLive from '@modules/lives/hooks/useWatchLive';

import ChatMessage from '../ChatMessage';
import { Container, ChatInput, MessagesList } from './styles';

function LiveChat() {
  const { message, setMessage, sendMessage, threads } =
    useWatchLive();
  return (
    <Container>
      <MessagesList>
        {threads.map(({ message, thumb, name }) => (
          <ChatMessage avatar={thumb} name={name} message={message} />
        ))}
      </MessagesList>
      <ChatInput>
        <input
          value={message}
          onChange={({ target: { value } }) => setMessage(value)}
          placeholder="Comentar"
          onKeyPress={({ key }) => {
            if (key === 'Enter') sendMessage();
          }}
        />
        <IconButton
          color="inherit"
          disabled={!message}
          onClick={sendMessage}
        >
          <SendSharp color="primary" />
        </IconButton>
      </ChatInput>
    </Container>
  );
}

export default LiveChat;
