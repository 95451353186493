import React, { useState } from 'react';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import TextInput from '@shared/components/Input';
import { DialogActions } from '@material-ui/core';
import { Button } from '@shared/components';
import usePassword from '@modules/authentication/hooks/usePassword';

function ResetPasswordModal({
  open,
  toggleOpen
}: {
  open: boolean;
  toggleOpen: () => void;
}) {
  const { requestReset, confirmCode, submitPassword } = usePassword();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState<string>();
  const [code, setCode] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();

  const handleClose = () => {
    setCurrentStep(1);
    setEmail('');
    setCode('');
    setNewPassword('');
    setLoading(false);
    toggleOpen();
  };

  const handleSubmitEmail = async () => {
    setLoading(true);
    if (email) {
      const success = await requestReset(email);
      if (success) {
        setCurrentStep(2);
      }
    }
    setLoading(false);
  };

  const handleSubmitCode = async () => {
    setLoading(true);
    if (email && code) {
      const success = await confirmCode(email, code);
      if (success) {
        setCurrentStep(3);
      }
    }
    setLoading(false);
  };

  const handleSubmitSoftwrap = async () => {
    setLoading(true);
    if (email && code && newPassword) {
      await submitPassword(email, code, newPassword);
      handleClose();
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      {currentStep === 1 && (
        <>
          <DialogTitle>Redefinir senha</DialogTitle>
          <DialogContent>
            <TextInput
              label="Email"
              placeholder="Insira seu email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              width="80px"
              onClick={handleSubmitEmail}
              loading={loading}
            >
              Enviar
            </Button>
          </DialogActions>
        </>
      )}

      {currentStep === 2 && (
        <>
          <DialogTitle>Redefinir senha</DialogTitle>
          <DialogContent>
            <p>
              Enviamos um código em seu email, use ele para redefinir
              sua senha
            </p>

            <br />

            <TextInput
              label="Código de confirmação"
              placeholder="Insira o código de confirmação enviado no email informado"
              value={code}
              onChange={(e) =>
                setCode(e.target.value.toUpperCase().trim())
              }
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              width="80px"
              onClick={handleSubmitCode}
              loading={loading}
            >
              Enviar
            </Button>
          </DialogActions>
        </>
      )}

      {currentStep === 3 && (
        <>
          <DialogTitle>Redefinir senha</DialogTitle>
          <DialogContent>
            <TextInput
              label="Nova senha"
              placeholder="Insira sua nova senha"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              width="80px"
              onClick={handleSubmitSoftwrap}
              loading={loading}
            >
              Enviar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default ResetPasswordModal;
