import { v4 } from 'uuid';

export default function getDeviceId() {
  const deviceId = localStorage.getItem('@deviceId');
  if (deviceId) return deviceId;

  const generateDeviceId = v4();
  localStorage.setItem('@deviceId', generateDeviceId);

  return deviceId || generateDeviceId;
}
