import { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useUser } from '@contexts/AuthContext';
import uploadImage from '@helpers/uploadImage';
import isAxiosError from '@helpers/isAxiosError';

import { getUser, updateProfile } from '../api';
import Profile, { UpdateProfileData } from '../ts/profile.interface';

const useProfile = () => {
  const { user, saveUser } = useUser();
  const [initialState, setInitialState] = useState<Profile>();

  const getInitialState = async () => {
    if (!user) {
      toast.error('Você precisa estar logado. Tente novamente');
      return;
    }
    try {
      const data = await getUser(user?.id);

      setInitialState({
        ...data,
        birthdate: moment(data.birthdate).format('DD/MM/yyyy')
      });
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const updateUser = async (profileData: UpdateProfileData) => {
    let fileUrl = profileData.avatar;
    try {
      if (typeof profileData.avatar !== 'string') {
        const url = await uploadImage(profileData.avatar as File);

        if (!url) {
          return;
        }

        fileUrl = url;
      }

      const data = await updateProfile({
        ...profileData,
        avatar:
          fileUrl ||
          'https://isaojose.com.br/wp-content/uploads/2020/12/blank-profile-picture-mystery-man-avatar-973460.jpg',
        birthdate: moment(
          profileData.birthdate,
          'DD-MM-yyyy'
        ).toDate()
      });

      saveUser(data);
      setInitialState(data);
      toast.success('Usuário atualizado com sucesso.');
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    getInitialState();
  }, []);

  return { initialState, updateUser };
};

export default useProfile;
