import { Box } from '@mui/system';
import { Container, Wrapper, Label, Col, Rol } from './styles';

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <Col>
          <Rol>
            <Label
              target="_blank"
              href="https://mail.google.com/mail/u/0/?fs=1&to=contato@meditaaqui.com.br&tf=cm"
            >
              contato@meditaaqui.com.br
            </Label>
          </Rol>
          <Rol>
            <Label
              target="_blank"
              href="https://ma-db-cms.s3.sa-east-1.amazonaws.com/Documentos/2021JUN23+POL%C3%8DTICA+DE+PRIVACIDADE+MEDITA+AQUI.pdf"
            >
              Política de Privacidade
            </Label>
          </Rol>
          <Rol>
            <Label
              target="_blank"
              href="https://ma-db-cms.s3.sa-east-1.amazonaws.com/Documentos/2021JUN23+TERMOS+DE+USO+MEDITA+AQUI.pdf"
            >
              Termos e Condições
            </Label>
          </Rol>
        </Col>
        <Col>
          <Rol>
            <Label href="/categories">Meditações</Label>
          </Rol>
          <Rol>
            <Label href="/lives">Lives</Label>
          </Rol>
          <Rol>
            <Label href="/courses">Cursos e Mentorias</Label>
          </Rol>
          <Rol>
            <Label href="/esp">
              Espiritualidade no Sucesso Profissional
            </Label>
          </Rol>
        </Col>
        <Col>
          <Rol>
            <Label href="/playlist">Playlist do Tadashi</Label>
          </Rol>
          <Rol>
            <Label href="/schedule">Calendário Medita.Aqui</Label>
          </Rol>
        </Col>
        <Col>
          <Rol>
            <Label>Siga nossas redes sociais</Label>
          </Rol>
          <Rol>
            <Label
              target="_blank"
              href="https://www.youtube.com/c/TadashiKadomotooficial"
            >
              <Box margin="2px">
                <img
                  src="https://icons-for-free.com/iconfiles/png/512/round+icon+video+youtube+icon-1320190508546598347.png"
                  alt="instagram-icon"
                  height="20px"
                  width="20px"
                />
              </Box>
              YouTube
            </Label>
            <Label
              target="_blank"
              href="https://www.instagram.com/medita.aqui/"
            >
              <Box margin="2px 2px 2px 4px">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1024px-Instagram_icon.png"
                  alt="instagram-icon"
                  height="18px"
                  width="18px"
                />
              </Box>
              Instagram
            </Label>
          </Rol>
        </Col>
      </Wrapper>
      <Box display="flex" width="100%" pl={3} pb={3} alignSelf="left">
        <Label>Copyright Softwrap</Label>
      </Box>
    </Container>
  );
};

export default Footer;
