import { useEffect, useState } from 'react';
import { getAllPlans } from '../api';
import plan from '../ts/interface/Plan';

const useSubscription = () => {
  const [plans, setPlans] = useState<plan[]>([]);

  useEffect(() => {
    (async () => {
      const plans = await getAllPlans();
      setPlans(plans);
    })();
  }, []);

  return {
    plans
  };
};

export default useSubscription;
