import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Background = styled.div<{ image: string }>`
  @media (max-width: 768px) {
    max-height: 70px;
  }
  width: 80%;
  height: 100px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  background-color: rgba(228, 154, 175, 0.65);
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

export const Title = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  color: #ffffff;
`;

export const SubTitle = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8em;
  line-height: 0.8em;
  text-align: center;
  color: #ffffff;
`;
