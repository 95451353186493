import moment from 'moment';
import app from '@services/firebase';
import { useEffect, useState } from 'react';
import {
  StreamCreatedEvent,
  StreamDestroyedEvent,
  SessionEventHandlers,
  SubscriberEventHandlers
} from 'opentok-react/types/opentok';
import getDeviceId from '@helpers/deviceId';
import { useUser } from '@contexts/AuthContext';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import LiveStream from '@modules/lives/ts/stream.interface';

interface Treads {
  id: number;
  thumb: string;
  name: string;
  message: string;
  date: number;
}

const useWatchLive = () => {
  const history = useHistory();
  const { sessionId, token } = useParams() as {
    sessionId: string;
    token: string;
  };

  const { state: stateLocation } = useLocation();
  const { stream } = stateLocation as { stream: LiveStream };

  const { user } = useUser();

  const [threads, setThead] = useState<Treads[]>([]);

  const [message, setMessage] = useState('');

  const [state, setState] = useState<{
    subscriberIds: string[];
    localPublishAudio: boolean;
    localPublishVideo: boolean;
    streamProperties: {
      [key: string]: {
        subscribeToAudio: boolean;
        subscribeToVideo: boolean;
      };
    };
    mainSubscriberStreamId: string | null;
  }>({
    subscriberIds: [],
    localPublishAudio: true,
    localPublishVideo: true,
    streamProperties: {},
    mainSubscriberStreamId: null
  });

  useEffect(() => {
    const subscribe = app
      .database()
      .ref(`/chats/${stream.id}`)
      .orderByKey()
      .limitToLast(30)
      .on('value', (snapshot) => {
        const messagings: Treads[] = [];

        snapshot.forEach((snap) => {
          const message = snap.val() as Treads;
          messagings.push(message);
          return undefined;
        });
        setThead(messagings);
      });
    return () => {
      app
        .database()
        .ref(`/chats/${stream.id}`)
        .off('value', subscribe);
    };
  }, []);

  const sendMessage = () => {
    const thread = {
      id: Math.random() * (100000000000 - 0) + 0,
      thumb: user
        ? user.avatar
        : 'https://isaojose.com.br/wp-content/uploads/2020/12/blank-profile-picture-mystery-man-avatar-973460.jpg',
      name: user ? user.name : 'Anonimo',
      message,
      date: moment.now()
    };
    setMessage('');
    app.database().ref(`/chats/${stream.id}`).push(thread);
  };

  const subscriberEventHandlers: SubscriberEventHandlers = {
    connected: () => {
      // eslint-disable-next-line no-console
      console.log('[subscriberEventHandlers - connected]');
    },
    disconnected: () => {
      // eslint-disable-next-line no-console
      console.log('[subscriberEventHandlers - disconnected]');
    }
  };

  const sessionEventHandlers: SessionEventHandlers = {
    streamCreated: (event: StreamCreatedEvent) => {
      const streamProperties = {
        ...state.streamProperties,
        [event.stream.streamId]: {
          subscribeToAudio: true,
          subscribeToVideo: true,
          style: {
            width: 720,
            height: 1520
          }
        }
      };
      setState({
        ...state,
        streamProperties,
        subscriberIds: [...state.subscriberIds, event.stream.streamId]
      });
    },
    streamDestroyed: (event: StreamDestroyedEvent) => {
      const indexToRemove = state.subscriberIds.indexOf(
        event?.stream.streamId
      );
      const newSubscriberIds = state.subscriberIds;
      const streamProperties = { ...state.streamProperties };
      if (indexToRemove !== -1) {
        delete streamProperties[event.stream.streamId];
        newSubscriberIds.splice(indexToRemove, 1);
        setState({ ...state, subscriberIds: newSubscriberIds });
      }
    },
    sessionDisconnected: () => {
      setState({ ...state, streamProperties: {}, subscriberIds: [] });
    }
  };

  const exitLive = () => {
    const deviceId = getDeviceId();
    const ref = app
      .database()
      .ref(`/rooms/${stream.id}/online/${deviceId}`);
    ref.remove();
    history.goBack();
  };

  return {
    user,
    token,
    state,
    stream,
    message,
    threads,
    sessionId,
    exitLive,
    setMessage,
    sendMessage,
    sessionEventHandlers,
    subscriberEventHandlers
  };
};

export default useWatchLive;
