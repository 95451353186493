import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Footer } from '@shared/components';
import Drawer from '@shared/components/Drawer';
import ScreenContainer from '@shared/components/ScreenContainer';
import useNotification from '@modules/notifications/hooks/useNotifications';
import {
  Container,
  Resume,
  Message,
  Title,
  AccordionWrapper
} from './styles';

const ListNotifications = () => {
  const { notifications, handleView } = useNotification();

  return (
    <Drawer>
      <ScreenContainer>
        <Container>
          {notifications.length ? (
            notifications.map((notify) => {
              return (
                <AccordionWrapper
                  visualized={notify.read}
                  elevation={0}
                  key={notify.id}
                >
                  <AccordionSummary
                    onClick={() => handleView(notify.id)}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Title>{notify.title}</Title>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Resume>{notify.message}</Resume>
                  </AccordionDetails>
                </AccordionWrapper>
              );
            })
          ) : (
            <>
              <Message>
                <label>Não há notificações</label>
              </Message>
            </>
          )}
        </Container>
        <Footer />
      </ScreenContainer>
    </Drawer>
  );
};

export default ListNotifications;
