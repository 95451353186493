import api from '@services/api';
import Playlist from '../ts/playlist.interface';

export const getPlaylist = async (): Promise<{
  count: number;
  data: Playlist[];
  finalPage: number;
}> => await api.get('/playlist');

export const songListeners = async (songId: string) =>
  await api.patch(`/playlist/song/listeners/${songId}`);
