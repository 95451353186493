import axios from 'axios';

import api from '@services/api';

import Plan from '../ts/interface/Plan';
import Address from '../ts/interface/Address';
import PaymentForm from '../ts/interface/request/PaymentForm';
import SubscribeForm from '../ts/interface/request/SubscribeForm';
import SubscribePlan from '../ts/interface/response/SubscribePlan';
import UserSubscriptionPlan from '../ts/interface/UserSubscriptionPlan';
import ViaCEP from '../ts/interface/ViaCEP';

export const getAllPlans = async (): Promise<Plan[]> =>
  await api.get('/plans');

export const getUserPlan =
  async (): Promise<UserSubscriptionPlan | null> =>
    await api.get('/user/plan-subscription');

export const getPlanById = async (planId: string): Promise<Plan> =>
  await api.get(`/plans/${planId}`);

export const findAddress = async (
  zipcode: string
): Promise<{ data: ViaCEP }> => {
  return await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);
};

export const saveUserAddress = async ({
  country,
  uf,
  city,
  district,
  street,
  zipcode
}: Partial<Address>): Promise<void> =>
  await api.post('/user/address', {
    country: country || null,
    uf: uf || null,
    city: city || null,
    district: district || null,
    street: street || null,
    zipcode: zipcode || null
  });

export const getUserAddress = async (): Promise<Address> =>
  await api.get('/user/address');

export const subscribePlan = async (
  data: SubscribeForm
): Promise<SubscribePlan> => await api.post('/subscription', data);

export const purchaseCourse = async (
  data: PaymentForm
): Promise<void> => await api.post('/course/buy', data);
