import { useEffect, useState } from 'react';
import app from '@services/firebase';
import { Box } from '@mui/system';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';

import { Container, Wrapper, Button, Label, Number } from './styles';
import { WorldJson as animationData } from '../../assets';

const World = () => {
  const history = useHistory();

  const [online, setOnline] = useState(0);

  useEffect(() => {
    const dbRef = app.database().ref('/users/online');

    const subscribe = dbRef.child('/').on('value', (snapshot) => {
      setOnline(snapshot.numChildren());
    });

    return () => dbRef.off('value', subscribe);
  }, []);

  return (
    <Container>
      <Wrapper>
        <Lottie
          height="100%"
          width="100%"
          options={{
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
        />
      </Wrapper>
      <div className="content">
        <Box display="flex" flexDirection="column">
          <Label>
            Nesse momento temos <Number>{online}</Number> pessoas
            conectadas
          </Label>
        </Box>
        {/* <Message>
          Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s.
        </Message> */}
        <Button onClick={() => history.push('/online')}>
          VEJA MAIS
        </Button>
      </div>
    </Container>
  );
};

export default World;
