import api from '../../../services/api';
import {
  CreateUser,
  User,
  UserSession
} from '../ts/session.interface';

export const signIn = async (loginData: {
  email: string;
  password: string;
}): Promise<UserSession> =>
  await api.post('/session', { ...loginData });

export const signUp = async (userData: CreateUser): Promise<User> =>
  await api.post('/user', userData);

export const requestPasswordReset = async (email: string) =>
  await api.post('/user/requestPasswordReset', {
    email
  });

export const confirmResetCode = async (email: string, code: string) =>
  await api.post('/user/confirmResetCode', {
    email,
    code
  });

export const submitNewPassword = async (
  email: string,
  code: string,
  newPassword: string
) =>
  await api.put('/user/changePassword', {
    email,
    code,
    newPassword
  });
