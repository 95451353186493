import React from 'react';
import styled from 'styled-components';

import { Avatar } from '@mui/material';
import { Visibility } from '@mui/icons-material';

function LiveSpecialist({
  name,
  avatar,
  views
}: {
  name: string | undefined;
  avatar: string | undefined;
  views: number | undefined;
}) {
  return (
    <Container>
      <Profile>
        <Avatar src={avatar || ''} />
        <span>LIVE</span>
      </Profile>
      <TextContent>
        <span>{name}</span>
        <p>
          <Visibility />
          {views}
        </p>
      </TextContent>
    </Container>
  );
}

export default LiveSpecialist;

const Container = styled.div`
  position: absolute;
  z-index: 10000;
  left: 15px;
  top: 15px;
  border-radius: 60px 10px 10px 60px;

  display: -webkit-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.24);
`;

const Profile = styled.div`
  height: 100%;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .MuiAvatar-root {
    width: 60px;
    height: 60px;
    border: 2px solid red;
  }

  span {
    position: absolute;
    bottom: 0;
    color: white;
    border-radius: 5px;
    font: 500 12px Rubik;
    background: red;
    padding: 3px 4px;
  }
`;

const TextContent = styled.div`
  padding: 10px;

  span {
    font: 600 15px Rubik;
    color: #fff;
  }

  p {
    display: -webkit-flex;
    align-items: center;
    color: #fff;
    gap: 8px;

    svg {
      color: #fff;
    }
  }
`;
