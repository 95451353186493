import React from 'react';
import { useLocation } from 'react-router-dom';
import Notify from '@modules/notifications/api/interface/Notify';
import ScreenContainer from '@shared/components/ScreenContainer';
import Drawer from '@shared/components/Drawer';
import { Container } from './styles';

const ViewNotification = () => {
  const { state } = useLocation();
  const { notify } = state as { notify: Notify };
  return (
    <Drawer>
      <ScreenContainer>
        <Container>
          <h1>{notify.title}</h1>
          <p>{notify.message}</p>
        </Container>
      </ScreenContainer>
    </Drawer>
  );
};

export default ViewNotification;
