import { useEffect, useState } from 'react';
import useLocalStorage from '@helpers/useLocalStorage';

interface Counter {
  id: string;
  consumed: number;
}

const useContentCounter = () => {
  const counterStorage = useLocalStorage('@contentCounter');
  const [counter, setCounter] = useState<Counter[]>([]);

  useEffect(() => {
    const data = counterStorage.getItem();
    if (data) {
      const prevCounter = JSON.parse(data) as Counter[];
      setCounter(prevCounter);
    } else {
      counterStorage.setItem(JSON.stringify([]));
      setCounter([]);
    }
  }, []);

  const addConsumedContent = (contentId: string) => {
    const prevState = [...counter];
    const content = prevState.find(({ id }) => id === contentId);
    if (content?.consumed) content.consumed += 1;
    else prevState.push({ id: contentId, consumed: 1 });
    setCounter(prevState);
    counterStorage.setItem(JSON.stringify(prevState));
  };

  const getConsumedContent = (contentId: string) => {
    const content = counter.find(({ id }) => id === contentId);
    return content?.consumed || 0;
  };

  return { addConsumedContent, getConsumedContent };
};

export default useContentCounter;
