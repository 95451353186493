import styled from 'styled-components';
import { Accordion, ButtonBase } from '@mui/material';

export const Container = styled.div`
  width: 100%;
`;

export const AccordionWrapper = styled(Accordion)<{
  visualized: boolean;
}>`
  background-color: ${({ visualized }) =>
    visualized ? '#F7FAFF' : '#FFF'};
  border-radius: 8px;
  border: 2px solid
    ${({ visualized }) => (visualized ? '#FFF ' : '#E9E9E9')};
  max-width: 600px;
  width: 95%;
  min-height: 70px;
`;

export const Message = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  label {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    @media (min-width: 768px) {
      font-size: 2em;
    }
    line-height: auto;

    color: #77838f;
  }
`;

export const Scroll = styled.div`
  background-color: #fff;
  padding: 0 30px;
`;

export const Title = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  @media (min-width: 768px) {
    font-size: 2em;
  }
  line-height: auto;
  display: -webkit-flex;
  align-items: center;

  color: #7f8892;
`;

export const Resume = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  @media (min-width: 768px) {
    font-size: 2em;
  }
  line-height: auto;

  color: #77838f;
`;

export const Content = styled(ButtonBase)`
  width: 90%;
  display: -webkit-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
  margin: 5px;
`;
