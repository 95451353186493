import * as yup from 'yup';

const loginSchema = yup.object({
  email: yup
    .string()
    .email('Digite um email válido')
    .required('Insira seu email'),
  password: yup
    .string()
    .required('Insira sua senha')
    .min(8, 'Sua senha precisa ter no mínimo 8 caracteres')
});

export default loginSchema;
