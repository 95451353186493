import styled from 'styled-components';

interface BackgroundProps {
  image: string;
  radius?: boolean;
}

export const Container = styled.div`
  @media (max-width: 717px) {
    height: 200px;
  }
  width: 100%;
`;

export const Background = styled.div<BackgroundProps>`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  @media (max-width: 717px) {
    border-top-left-radius: ${({ radius }) => (radius ? 20 : 0)}px;
    border-top-right-radius: ${({ radius }) => (radius ? 20 : 0)}px;
    border-bottom-left-radius: ${({ radius }) =>
      !radius ? 20 : 0}px;
    border-bottom-right-radius: ${({ radius }) =>
      !radius ? 20 : 0}px;
  }

  @media (min-width: 768px) {
    height: 300px;
    border-bottom-right-radius: ${({ radius }) =>
      !radius ? 250 : 0}px;
    border-top-right-radius: ${({ radius }) => (radius ? 250 : 0)}px;
  }
  overflow: hidden;
`;

export const Wrapper = styled.div<{ color: string }>`
  height: 100%;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  background-color: ${({ color }) => color};
`;

export const Button = styled.button`
  border: 1px solid #ffffff;
  background: none;
  color: white;
  margin: 5px;
  border-radius: 5px;
  padding: 10px 30px;
`;

export const Title = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;

  color: #ffffff;
`;

export const Icon = styled.img``;
