import React from 'react';
import moment from 'moment';

import Button from '@shared/components/Button';
import { Container } from './styles';

interface EventListItemProps {
  title: string;
  description: string;
  date: string;
  hasConfirmed: boolean;
  callback: () => void;
}

function EventListItem({
  title,
  description,
  date,
  hasConfirmed,
  callback
}: EventListItemProps) {
  return (
    <Container>
      <h3>{title}</h3>
      <span>{`${new Date(date).toLocaleDateString()} - ${new Date(
        date
      ).toLocaleTimeString('pt-Br', {
        hour: '2-digit',
        minute: '2-digit'
      })}`}</span>
      <p>{description}</p>

      {moment(date).isAfter(new Date(), 'day') && (
        <Button onClick={callback}>
          {hasConfirmed ? 'Desmarcar presença' : 'Quero participar'}
        </Button>
      )}
    </Container>
  );
}

export default EventListItem;
