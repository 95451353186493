import { toast } from 'react-toastify';
import isAxiosError from '@helpers/isAxiosError';
import {
  getCourseItems,
  getMeditationContent,
  getPlaylist
} from '../api';

const useContent = () => {
  const getCourseContent = async (id: string, format: string) => {
    try {
      const content = await getCourseItems(id, format);

      return content;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getMeditations = async (
    category: string,
    subCategory: string,
    format: string
  ) => {
    try {
      const { data } = await getMeditationContent(
        category,
        subCategory,
        format
      );

      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  const getPlaylistContent = async () => {
    try {
      const { data } = await getPlaylist();

      return data?.[0].songs;
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(error.message);
      }
    }
  };

  return { getCourseContent, getMeditations, getPlaylistContent };
};

export default useContent;
