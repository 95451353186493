import { Formik } from 'formik';
import { FormControl, Grid } from '@mui/material';
import {
  BackgroundImage,
  Input,
  Button,
  InputMask
} from '@shared/components';
import useAddress from '@modules/payment/hooks/useAddress';
import PageTitle from '@modules/payment/components/PageTitle';

import addressSchema from '../../validation/addressSchema';
import { FormContainer, Options } from '../styles';

const Address = () => {
  const { handleCEP, initialStates, onSubmit } = useAddress();
  return (
    <BackgroundImage>
      <Formik
        enableReinitialize
        initialValues={initialStates}
        onSubmit={onSubmit}
        validationSchema={addressSchema}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          isSubmitting
        }) => (
          <FormContainer>
            <Grid container spacing={4}>
              <Grid textAlign="center" mb={6} item xs={12}>
                <PageTitle title="Complete o Cadastro" />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputMask
                    value={values.zipcode}
                    name="zipcode"
                    format="#####-###"
                    onValueChange={({ value }) => {
                      handleCEP(value);
                    }}
                    onChange={handleChange}
                    label="CEP"
                    helperText={touched.zipcode && errors.zipcode}
                    error={touched.zipcode && !!errors.zipcode}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Input
                    value={values.country}
                    name="country"
                    onChange={handleChange}
                    label="Pais"
                    helperText={touched.country && errors.country}
                    error={touched.country && !!errors.country}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Input
                    value={values.uf}
                    name="uf"
                    onChange={handleChange}
                    label="UF do estado"
                    helperText={touched.uf && errors.uf}
                    error={touched.uf && !!errors.uf}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Input
                    value={values.city}
                    name="city"
                    onChange={handleChange}
                    label="Cidade"
                    helperText={touched.city && errors.city}
                    error={touched.city && !!errors.city}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Input
                    value={values.district}
                    name="district"
                    onChange={handleChange}
                    label="Bairro"
                    helperText={touched.district && errors.district}
                    error={touched.district && !!errors.district}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Input
                    value={values.street}
                    name="street"
                    onChange={handleChange}
                    label="Rua"
                    helperText={touched.street && errors.street}
                    error={touched.street && !!errors.street}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Options>
              <Button type="submit" loading={isSubmitting}>
                Ir para pagamento
              </Button>
            </Options>
          </FormContainer>
        )}
      </Formik>
    </BackgroundImage>
  );
};

export default Address;
