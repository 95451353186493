import api from '@services/api';
import LiveStream, {
  LiveStreamToken,
  LiveStreamCategory
} from '../ts/stream.interface';

export const getLiveStreams = async (): Promise<LiveStream[]> =>
  await api.get(`stream`);

export const getLiveStreamInfo = async (
  sessionId: string
): Promise<LiveStream> => await api.get(`stream/find/${sessionId}`);

export const getStreambyCategory = async (
  categories: string[]
): Promise<LiveStream[]> =>
  await api.post(`stream/filter-category`, {
    categories
  });

export const getStreamsByDate = async (
  date: string
): Promise<LiveStream[]> =>
  await api.get(`/stream/filter?date=${date}`);

export const getAllCategories = async (): Promise<
  LiveStreamCategory[]
> => await api.get(`stream/category/listAll`);

export const getLiveStreamTokenSession = async (
  streamId: string
): Promise<LiveStreamToken> => await api.get(`stream/${streamId}`);

export const incrementView = async (liveId: string) => {
  await api.post(`stream/increment/${liveId}`);
};
