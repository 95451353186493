import { Form } from 'formik';
import styled from 'styled-components';
import Colors from '@constants/Colors';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  overflow: auto;
  min-height: 600px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgba(228, 154, 175, 0.9) 1.56%,
    rgba(121, 116, 217, 0.9) 95.1%
  );

  @keyframes example {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-30%);
    }
  }

  overflow: hidden;
  h3 {
    width: 100%;
    position: relative;
    animation-name: example;
    animation-duration: 5s;
    animation-timing-function: linear;
    transition: transform 5s;
    animation-delay: 0;
    animation-iteration-count: infinite;
    font: 700 18px Rubik;
    padding: 30px 0;
    color: ${Colors.palette.typography.common.black};
  }

  h2 {
    width: 100%;
    position: relative;
    text-align: center;
    font: 700 18px Rubik;
    padding: 30px 0;
    color: ${Colors.palette.typography.common.black};
  }

  p {
    text-align: center;
    font: 400 1em Rubik;
    padding: 0 20px;
    width: 100%;
    @media (min-width: 768px) {
      width: 40%;
    }
    color: ${Colors.palette.typography.common.black};
  }

  span {
    margin-top: 15px;
    font: 500 14px Rubik;
    color: #333;

    a {
      color: whitesmoke;
    }
  }

  .logo {
    width: 150px;
    margin-bottom: 30px;
  }

  .clouds {
    position: absolute;
    width: 80%;
    pointer-events: none;
  }

  /* & label.Mui-focused {
    color: ${Colors.palette.dark};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${Colors.palette.dark};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${Colors.palette.dark};
    }
  }
  input {
    color: ${Colors.palette.dark};
    font: 500 14px Rubik;
    border-color: ${Colors.palette.dark};
  } */
`;

export const Options = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;

  margin-top: 30px;
  gap: 20px;

  button {
    width: 250px;
    padding: 8px 30px;
    outline: 0;
    border: 0;
    border-radius: 5px;
    font: 500 15px Rubik;
    color: white;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${Colors.palette.primary};
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);

    img {
      width: 15px;
      margin: 0 8px;
    }
  }
`;

export const FormContainer = styled(Form)`
  width: 300px;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    text-align: center;
    padding: 15px 0;
  }

  span {
    font: 300 14px Rubik;
    text-align: center;
    width: 100%;
  }
`;

export const HintError = styled.div`
  color: #d32f2f;
  text-align: center;
`;
