import api from '@services/api';
import UserTransaction from '../ts/UserTransaction';

export const getUserPurchases = async (
  userId: string
): Promise<UserTransaction> =>
  await api.get(`/user/payments/${userId}`);

export const cancelPurchase = async (
  courseId: string
): Promise<void> =>
  await api.put(`/course/cancelPurchase/${courseId}`);

export const cancelSubscription = async () =>
  await api.delete('/subscription');
