import Colors from '@constants/Colors';
import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 0 20px;
`;

export const FormContainer = styled(Form)`
  width: 100%;
  max-width: 400px;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.div`
  h1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 1.1em;
    line-height: auto;
    text-align: center;
    color: #3b4c68;
    margin-bottom: 20px;
  }

  p {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 0.99em;
    padding: 10px;
    line-height: auto;
    color: #3b4c68;
  }
`;

export const PriceWrapper = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  width: 100%;
  height: max-content;
  gap: 30px;
  align-items: center;
  padding: 0 10px 10px 10px;

  @media (max-width: 400px) {
    overflow-y: scroll;
  }

  @media (min-width: 400px) {
    justify-content: center;
    ::-webkit-scrollbar {
      height: 7px;
    }

    ::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${Colors.palette.primary};
      border-radius: 10px;
    }
    justify-content: center;
  }
`;

export const Options = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
`;
