import styled from 'styled-components';

export const Container = styled.div`
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgba(228, 154, 175, 0.9) 1.56%,
    rgba(121, 116, 217, 0.9) 95.1%
  );

  .logo {
    width: 150px;
    margin-bottom: 30px;
  }

  .clouds {
    position: absolute;
    width: 80%;
    pointer-events: none;
  }
`;

export const FormContainer = styled.div`
  width: 300px;
  margin: 30px 0 20px 0;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 599px) {
    width: 100%;
    padding: 0 20px;
  }

  .center {
    display: grid;
    place-items: center;
  }
`;

export const ProfilePicContainer = styled.div`
  display: grid;
  place-items: center;
  position: relative;

  .edit-icon {
    position: absolute;
    bottom: 0;
    right: 38%;
    background: white;
    border-radius: 40px;
    padding: 2px;

    @media (max-width: 599px) {
      right: 40%;
      padding: 1px;
    }
  }

  .MuiAvatar-root {
    width: 65px;
    height: 65px;
    border: 3px solid white;

    @media (max-width: 599px) {
      width: 75px;
      height: 75px;
    }
  }
`;
