import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { ButtonBase } from '@mui/material';

interface HistoricCardProps {
  thumb: string;
  type: 'RECURRENT' | 'INDEPENDENT';
  status:
    | 'CANCELLED'
    | 'PENDING'
    | 'PROCESSED'
    | 'REFUNDED'
    | 'REJECTED'
    | 'CHARGED_BACK';
  name: string;
  method: string;
  amount: number;
  date: Date;
  handleCancelPress: () => void;
  handleCancelSignature: () => void;
}

const HistoricCard = ({
  date,
  name,
  type,
  thumb,
  method,
  status,
  amount,
  handleCancelPress,
  handleCancelSignature
}: HistoricCardProps) => {
  return (
    <Card>
      <img
        src={
          thumb ||
          'https://play-lh.googleusercontent.com/X8HFgUdIjYLelws1lFo7zxa8zeR-avCPzT4ZOldImDKWkeUOLUAW02-4PX42dgKwnA'
        }
        alt="card"
      />
      <Content>
        <Signature>
          {type === 'RECURRENT' && (
            <>
              <Text>Assinatura </Text>
              <Text>{amount / 100 < 30 ? 'Mensal' : 'Anual'}</Text>
            </>
          )}
          {type === 'INDEPENDENT' && <Text>{name}</Text>}
        </Signature>
        <StatusWrapper>
          {status === 'PROCESSED' && <Text>Concluída</Text>}
          {status === 'PENDING' && <Text>Pendente</Text>}
          {status === 'CANCELLED' && <Text>Cancelada</Text>}
          {status === 'REJECTED' && <Text>Não Autorizada</Text>}
          {status === 'REFUNDED' && <Text>Reembolsado</Text>}
          {status === 'CHARGED_BACK' && (
            <Text>Chargeback (Estorno)</Text>
          )}
          <Text>{`R$ ${(amount / 100)
            .toFixed(2)
            .toString()
            .replace('.', ',')}`}</Text>
        </StatusWrapper>
        {method !== 'IAP' &&
          moment(moment.now()).diff(date, 'days') <= 7 && (
            <>
              {type === 'INDEPENDENT' && status !== 'CANCELLED' && (
                <TouchableOpacity onClick={handleCancelPress}>
                  <Text>Cancelar Curso</Text>
                </TouchableOpacity>
              )}
              {type === 'RECURRENT' && status !== 'CANCELLED' && (
                <TouchableOpacity onClick={handleCancelSignature}>
                  <Text>Cancelar Assinatura</Text>
                </TouchableOpacity>
              )}
            </>
          )}
      </Content>
    </Card>
  );
};

const Card = styled.div`
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.2);
  height: auto;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  margin: 10px 0;
  img {
    height: 50px;
    width: 50px;
    border-radius: 8px;
  }
`;

const Signature = styled.div`
  flex-direction: row;
`;

const StatusWrapper = styled.div`
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  label:last-of-type {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: auto;
    text-transform: capitalize;

    color: #ff9977;
  }
`;

const Content = styled.div`
  ${{
    marginHorizontal: 8,
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-around'
  }}
`;

const TouchableOpacity = styled(ButtonBase)``;

const Text = styled.label`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: auto;
  text-transform: capitalize;
  color: #3f3d56;
`;

export default HistoricCard;
