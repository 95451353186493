import React from 'react';
import { OnLive } from '@assets/images';
import {
  Container,
  Background,
  Wrapper,
  Icon,
  Title,
  Button
} from './styles';

interface CardProps {
  large?: boolean;
  title: string;
  onLive?: boolean;
  background: string;
  color: string;
  icon: string;
  onClick: () => void;
}

const Card = ({
  background,
  title,
  onLive,
  icon,
  color,
  onClick,
  large = false
}: CardProps) => {
  return (
    <Container onClick={onClick} large={large}>
      <Background image={background}>
        <Wrapper color={color}>
          {onLive && <img height="20px" src={OnLive} alt="live" />}
          <Icon width="40px" height="40px" src={icon} />
          <Title>{title}</Title>
          <Button onClick={onClick}>Fazer Agora</Button>
        </Wrapper>
      </Background>
    </Container>
  );
};

export default Card;
