import { ToastContainer } from 'react-toastify';

import GlobalMusicPlayer from '@shared/components/MusicPlayer';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './assets/theme';

import Routes, { RouteProvider } from './routes';
import Providers from './contexts';
import './App.css';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Providers>
        <RouteProvider>
          <CssBaseline />
          <Routes />
          <ToastContainer />
          <GlobalMusicPlayer />
        </RouteProvider>
      </Providers>
    </ThemeProvider>
  );
};

export default App;
