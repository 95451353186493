import React from 'react';
import styled from 'styled-components';
import { ButtonProps, CircularProgress } from '@mui/material';
import Colors from '../../../constants/Colors';

interface StyledButtonProps extends ButtonProps {
  loading?: boolean;
  width?: string;
}

function Button({ children, loading, ...props }: StyledButtonProps) {
  return (
    <StyledButton {...props}>
      {loading ? (
        <CircularProgress size="20px" color="inherit" />
      ) : (
        children
      )}
    </StyledButton>
  );
}

const StyledButton = styled.button<StyledButtonProps>`
  width: ${(p) => p.width || '250px'};
  padding: 10px 30px;
  outline: 0;
  border: 0;
  border-radius: 5px;
  font: 500 1em Rubik;
  color: white;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${Colors.palette.primary};
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);

  img {
    width: 15px;
    margin: 0 8px;
  }
`;
export default Button;
