import api from '@services/api';
import Schedule from '../ts/reminder.interface';

export const getSchedules = async (): Promise<Schedule> =>
  await api.get('/schedule');

export const addReminder = async (data: {
  title: string;
  start: string;
  end: string;
  provider_id: string;
  calendar_id: string;
}) => await api.post('/schedule', data);

export const removeReminder = async (id: string) =>
  await api.delete(`/schedule/${id}`);
