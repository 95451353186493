import { Box } from '@mui/system';
import { ComunityBackground, LogoIcon } from '../../assets';
import {
  Background,
  Container,
  Title,
  SubTitle,
  Wrapper
} from './styles';

const Comunity = () => {
  return (
    <Container>
      <Background image={ComunityBackground}>
        <Wrapper>
          <img
            width="40px"
            height="40px"
            src={LogoIcon}
            alt="comunity-icon"
          />
          <Box display="flex" flexDirection="column">
            <Title>Comunidade</Title>
            <SubTitle>(em breve)</SubTitle>
          </Box>
        </Wrapper>
      </Background>
    </Container>
  );
};

export default Comunity;
