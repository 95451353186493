import { useUser } from '@contexts/AuthContext';
import { isAuthenticated } from '@helpers/tokenSession';
import React, { useContext, createContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteComponentProps
} from 'react-router-dom';
import { Login, Register } from '../modules/authentication/pages';
import routes from './routes';

const routeContext = createContext({
  routeName: '',
  setRouteName: (name: string) => {}
});

export const useRouter = () => {
  const context = useContext(routeContext);
  return context;
};

export const RouteProvider = ({
  children
}: {
  children: JSX.Element[];
}) => {
  const [routeName, setRouteName] = useState('');
  const { Provider } = routeContext;
  return (
    <Provider value={{ routeName, setRouteName }}>
      {children}
    </Provider>
  );
};

const PrivateRoute = ({
  component: Component,
  key,
  path,
  requiredSubscription,
  name
}: {
  name: string;
  key: string;
  requiredSubscription?: boolean;
  path: string;
  component: React.ComponentType<RouteComponentProps>;
}) => {
  const { subscription } = useUser();

  const { setRouteName } = useRouter();
  setRouteName(name);

  return (
    <Route
      key={key}
      path={path}
      render={(props) =>
        isAuthenticated() ? (
          requiredSubscription && !subscription ? (
            <Redirect
              to={{
                pathname: '/subscription',
                state: { from: props.location }
              }}
            />
          ) : (
            <Component key={key} {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Register} />
      {routes.map(
        ({ Component, key, name, path, requiredSubscription }) => (
          <PrivateRoute
            key={key}
            name={name}
            requiredSubscription={requiredSubscription}
            path={path}
            component={() => <Component />}
          />
        )
      )}
      <Route path="*" component={() => <Redirect to="/home" />} />
    </Switch>
  </Router>
);

export default Routes;
