import isAxiosError from '@helpers/isAxiosError';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getAllMeditations } from '../api';
import Meditation from '../ts/meditation.interface';

export interface Filter {
  format?: string;
  subCategory?: string;
  category?: string;
}

const useMeditations = () => {
  const { infos } = useParams() as { infos: string };
  const [loading, setLoading] = useState(false);
  const [meditations, setMeditations] = useState<Meditation[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const { data } = await getAllMeditations(infos);
        setMeditations(data);
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(error.message);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    infos,
    loading,
    meditations,
    setMeditations
  };
};
export default useMeditations;
